import { Box, Typography } from '@mui/material';
import "./style.css";


export const AppPrimaryBtn = ({ btnText, width, textColor, bgColor }) => {
  return (
    <Box className="appBtn" sx={{ background: bgColor, width, color: textColor }}>
      <Typography>{btnText}</Typography>
    </Box>
  )
}

export const UnderLBtn = ({ btnText, onClick, active }) => {
  return (
    <Box className={active ? "underSlideBtn underSlideBtnActve" : "underSlideBtn"} onClick={onClick}>
      <Typography>{btnText}</Typography>
      <span></span>
    </Box>
  )
}