import React, { useEffect, useState } from 'react';
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';

import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';

export default function ShippingInformationPage({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [typeDrop, setTypeDrop] = useState(false);
    const [typeDropVal, setTypeDropVal] = useState("Type");
    const [firstSection, setFirstSection] = useState([]);
    const [types, setTypes] = useState([]);
    const [products, setProducts] = useState([]);
    const BACKEND_URL = config.BACKEND_URL;

    useEffect(() => {
        
        axios
        .get(`${BACKEND_URL}/api/V1/our-caviar/content`)
        .then((response) => {
            setFirstSection(response.data.result.content);
            setTypes(response.data.result.types);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/our-caviar/products`)
        .then((response) => {
            setProducts(response.data.result.products);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="caviarListPage">
                <Box className='productHeaderBox'>
                    <Typography className='ambHeader' data-aos="fade-up">Shipping Information</Typography>
                    

                </Box>


                {/* mail section */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
