import React, { useEffect, useRef } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const MapComponent = ({ location }) => {
  const mapContainer = useRef(null);

  const markers = [
    { lng: 16.3738, lat: 48.2082, description: 'Vienna, Austria' },
    { lng: 13.405, lat: 52.52, description: 'Berlin, Germany' },
    { lng: 2.3522, lat: 48.8566, description: 'Paris, France' },
  ];

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current,
      style:
        'https://api.jawg.io/styles/jawg-dark.json?access-token=mm8Fby5IHniYBn6LudtCtxAYTJOplhRsn77BkcfxdmL0sBZ07835gPFUi31DECmE',
      zoom: 2,
      center: [35.82344533357617, 37.94117361537252,],
      dragPan: true,
      scrollZoom: true,
      dragRotate: true,
      touchZoomRotate: true,
      interactive: true,
    });

    location.forEach((marker) => {
      new maplibregl.Marker()
        .setLngLat([marker?.lng, marker?.lat])
        .setPopup(
          new maplibregl.Popup({ offset: 25 }).setText(marker?.description)
        )
        .addTo(map);
    });

    maplibregl.setRTLTextPlugin(
      'https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js',
      null,
      true
    );

    return () => map.remove();
  }, []);

  return <div ref={mapContainer} className="map-container" style={{ width: '400px', height: '300px' }} />;
};

export default MapComponent;
