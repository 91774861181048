import React, {useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import "./style.css";

import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

import { Box, Typography } from "@mui/material";
import topImg from "../../Assets/Images/w1500_q85.webp";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Profle() {
    const userSession = localStorage.getItem("User");
    const apiToken = localStorage.getItem("Token");
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [notify, setNotify] = useState([]);

    useEffect(() => {
        if (userSession !== undefined && userSession !== null) {
            const User = JSON.parse(userSession);
          console.log("userSession @@ =",userSession);
          setFirstName(User.first_name);
        } else {
          navigate("/login");
        }
      }, [userSession]);
      console.log("Check login @@",firstName );
      const handleLogout = () => {
        localStorage.removeItem("User");
        localStorage.removeItem("Token");
        // console.log("Logout");
        const notifys = () => toast.success("Logout successfully");
        setNotify(notifys);
        navigate("/login");
      };
    

    const cards = [
        {
            title: "My Orders",
            subtitle: "Track the progress of your order, or arrange an exchange or return",
            path: "/account?dr=0",
        },
        {
            title: "Account Details",
            subtitle: "View or change your sign-in information ",
            path: "/account?dr=1",
        },
        {
            title: "Email Preference",
            subtitle: "View and manage your email preferences",
            path: "/account?dr=3",
        },
        {
            title: "Address Book",
            subtitle: "Manage your billing or delivery address",
            path: "/account?dr=2",
        },
        {
            title: "Privacy settings",
            subtitle: "View and manage your privacy settings",
            path: "/account?dr=4",
        },
        {
            title: "Reward Page",
            subtitle: "View your loyality reward Page",
            path: "/loyalty-rewards",
        },
        {
            title: "Offer Pge",
            subtitle: "View your offer Page",
            path: "/offer",
        },
    ];
    const Card = ({ title, subtitle, path }) => {
        return (
            <Link to={path} className="card">
                <Box className="cardHeading">
                    <h3 className="card-title">{title}</h3>
                </Box>
                <Box className="cardSubtitle">
                    <Typography className="card-subtitle">{subtitle}</Typography>
                </Box>
            </Link>
        );
    };

    return (
        <Box className="accountContainer">
            <ToastContainer />
            <NavBar />
            <Box className="bannerContainer">
                <img src={topImg} className="topImg" />
                <Typography className="profileHeaderM">
                    WELCOME TO YOUR ACCOUNT
                </Typography>
                <Typography className="profileHeaderL">Good evening, {firstName}</Typography>
                <Typography style={{cursor:"pointer",color:"#4f33d1"}} className="profileHeaderM" onClick={handleLogout}>LOGOUT</Typography>
            </Box>
            <Box className="profileCards">
                {cards.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        subtitle={card.subtitle}
                        path={card.path}
                    />
                ))}
            </Box>
            <Footer />
        </Box>
    );
}
