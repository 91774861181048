import React, { useState } from 'react'
import "./style.css"

import { Box, Typography, useScrollTrigger } from '@mui/material';


import cookieSettingIcon from "../../Assets/Images/svgexport-11.svg";
import crossImg from "../../Assets/Images/crossIcon.png";
import lockIcon from "../../Assets/Images/lockIcon.png";
import crossIocn2 from "../../Assets/Images/crossIcon2.png"
import cookieBanner from "../../Assets/Images/cookieBaner.svg"
import blackArrowIcon from "../../Assets/Images/BlackArrow.png"

export default function CookiePop({ cookiePop, setCookiePop }) {
  const [showDetails, setShowDetails] = useState(false)


  return (
    <>
      <Box className="cookiePop">
        <img src={cookieSettingIcon} className='cookieSettingIcon' onClick={() => setCookiePop(true)} />


        <Box className="cookiePopBox" sx={{ display: cookiePop ? "flex" : "none" }}>
          <Box className="cookieNav">
            <Typography>Cookie settings</Typography>
            <img style={{ cursor: "pointer" }} src={crossImg} onClick={() => setCookiePop(false)} />
          </Box>
          <Box className="coPopMainSection">

            <Box className='coPInTextBox'>
              <img src={cookieBanner} className='Cbnr' />
              <Typography className='copText1'>Your current state</Typography>

              <Box className="coIWLabel">
                <img src={lockIcon} />
                <Typography className='copText2'>Necessary</Typography>
              </Box>
              <Box className="coIWLabel">
                <img src={crossIocn2} />
                <Typography className='copText2'>Preferences</Typography>
              </Box>
              <Box className="coIWLabel">
                <img src={crossIocn2} />
                <Typography className='copText2'>Statistics</Typography>
              </Box>
              <Box className="coIWLabel">
                <img src={crossIocn2} />
                <Typography className='copText2'>Marketing</Typography>
              </Box>

              <Box sx={{ marginBottom: showDetails ? "0" : "125px" }} mt={4} className="coIWLabel" onClick={() => setShowDetails(!showDetails)}>
                <Typography className='copText2 BlueActiveTText'>{showDetails ? "Hide details" : "Show details"}</Typography>
                <img src={blackArrowIcon} className='DDropArrow' style={{ rotate: showDetails ? "270deg" : "90deg" }} />
              </Box>
              <Box className="cookieDetailBox" sx={{ display: showDetails ? "block" : "none" }}>
                <Typography className='cdTLabel'>Consent date:</Typography>
                <Typography className='cdTValue'>Aug 6, 2024 - 07:21:54 PM GMT+5:30</Typography>

                <Typography className='cdTLabel'>Your consent ID:</Typography>
                <span className='cdTValue'>VXWQJQ5EjYZsYkv47lwUyc0pjYw2Aq <br /> Cy1SniEhlJ+kWzHUtnjm0XSA==</span>
              </Box>
            </Box>

            <Box className="cookieFooter">
              <Box className="coBtn" sx={{ border: "1px solid #141414" }}>
                <Typography sx={{ color: '#141414' }}>Withdraw your consent</Typography>
              </Box>

              <Box className="coBtn" sx={{ background: "#1032CF", }}>
                <Typography>Change your consent</Typography>
              </Box>
            </Box>
          </Box>


        </Box>
      </Box>
    </>
  )
}
