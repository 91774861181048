import React, { useEffect, useState } from 'react';
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';

import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';

export default function PrivacyPolicyPage({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [typeDrop, setTypeDrop] = useState(false);
    const [typeDropVal, setTypeDropVal] = useState("Type");
    const [firstSection, setFirstSection] = useState([]);
    const [types, setTypes] = useState([]);
    const [products, setProducts] = useState([]);
    const BACKEND_URL = config.BACKEND_URL;

    useEffect(() => {
        
        axios
        .get(`${BACKEND_URL}/api/V1/our-caviar/content`)
        .then((response) => {
            setFirstSection(response.data.result.content);
            setTypes(response.data.result.types);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/our-caviar/products`)
        .then((response) => {
            setProducts(response.data.result.products);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="caviarListPage">
                <Box className='productHeaderBox'>
                    <Typography className='ambHeader' data-aos="fade-up">Privacy policy</Typography>
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>What personal data we collect and why we collect it</Typography>
                    <Typography sx={{fontSize:"21px",fontWeight:"400",marginTop:"25px"}}>Comments</Typography>
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.</Typography>
                    
                    <Typography sx={{fontSize:"21px",fontWeight:"400",marginTop:"25px"}}>Contact Forms & Cookies</Typography>    
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.</Typography>
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.</Typography>
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.</Typography>
                    <Typography sx={{fontSize:"21px",fontWeight:"400",marginTop:"25px"}}>Embedded Content From Other Websites</Typography>
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</Typography>
                    <Typography sx={{width:"100% !important",marginTop:"14px",color:"#ffffff"}} className='productPageSuhHeader'>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</Typography>

                </Box>


                {/* mail section */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
