import React, { useState } from 'react';
import "./style.css";

//components
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import VideoWithPlayButton from "../../Components/VideoPlayer"
import CookiePop from "../../Components/CookiePop"

//images
import rafaImg1 from "../../Assets/Images/rafaImg1.jpeg"
import rightImg from "../../Assets/Images/right.png";
import closeImg from "../../Assets/Images/close.png";
import slideImg1 from "../../Assets/Images/rafaSliderImg1.jpeg"
import slideImg2 from "../../Assets/Images/rafaSliderImg2.jpeg"
import slideImg3 from "../../Assets/Images/rafaSliderImg3.jpeg"
import caviBolImg from '../../Assets/Images/caviIBolImg.jpg'
import redFlowar from "../../Assets/Images/redFlowar.png"
import chefSection from "../../Assets/Images/chefSection.jpg";
import DownArrowIcon from "../../Assets/Images/chevron-down.png";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import EmailCommunity from "../../Components/EmailCommunity";

export default function RefaCagali({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [firstRow, setFirstRow] = useState(false);
    const [secondRow, setSecondRow] = useState(false);
    const [isRotated, setIsRotated] = useState(false);
    const [isRotated2, setIsRotated2] = useState(false);

    const toggleRotation = () => {
        setIsRotated(!isRotated);
    };
    const toggleRotation2 = () => {
        setIsRotated2(!isRotated2);
    };

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="rafaPage">
                {/* <Box className="rafahomeTextBox">
                    <Typography>N25 AMBASSADOR, RAFA CAGALI</Typography>
                </Box> */}
                <Box className="rafaImgBox1">
                    <Box className="ovCInneerBox" >
                        <Box className="ovTextBox" style={{ background : "transparent", width : "50%" }}>
                            <Typography sx={{fontSize:"38px !important"}} className="ovTbHeader">WELCOME TO LOYALTY REWARDS</Typography>
                            <Typography className="summery14">Gain access to exclusive benefits and experiences</Typography>
                        </Box>
                    </Box>
                    <img src={rafaImg1} />
                </Box>
                <Box className="RafatextBox">
                    <Typography style={{textAlign:"center",fontWeight:"500",fontSize:"32px"}} className='rafaTBHText'>Our Rewards Program</Typography>
                    <Typography className='rafaTBNText'>NET‑A‑PORTER Rewards is our dedicated loyalty program that offers you access to a host of exclusive benefits, including Personal Shopper services, regular discounts and offers, complimentary express shipping and much more. Each tier is based on your yearly spend, with every item you buy counting towards your journey in becoming one of our most valued EIP customers, otherwise known as an Extremely Important Person.</Typography>
                </Box>
                <Box className="howWorkBox">
                    <Typography sx={{textAlign:"center",fontSize:"32px", fontWeight:"500"}}>How does it work?</Typography>
                    <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-around",marginTop:"20px",gap:"16px",padding:"12px 16px"}}>
                        <Box className="">
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center",paddingBottom:"13px"}}><svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M48.1667 24C48.1667 36.9787 37.6454 47.5 24.6667 47.5C11.688 47.5 1.16669 36.9787 1.16669 24C1.16669 11.0213 11.688 0.5 24.6667 0.5C37.6454 0.5 48.1667 11.0213 48.1667 24Z" stroke="white"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5002 34H14.5002V19H20.5002H28.5002H34.5002V34ZM21.5002 17C21.5002 15.346 22.8462 14 24.5002 14C26.1542 14 27.5002 15.346 27.5002 17V18H21.5002V17ZM28.5002 18V17C28.5002 14.791 26.7092 13 24.5002 13C22.2912 13 20.5002 14.791 20.5002 17V18H13.5002V35H35.5002V18H28.5002Z" fill="white"></path>
                            </svg></Typography>
                        <Typography sx={{textAlign:"center"}}><strong>1. Shop at NET-A-PORTER</strong></Typography>
                        <Typography sx={{marginTop:"16px"}}>Your tier is determined by how much you spend on your NET‑A‑PORTER account over a 12-month period, and every purchase you make takes you a step closer to becoming an EIP.</Typography>
                        </Box>
                        <Box className="">
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center",paddingBottom:"10px"}}><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="24" cy="24" r="23.5" stroke="white"></circle>
                                <path d="M31.2727 24V13.0909H14.9091V34.9091C20.6258 34.9091 32.6043 34.9091 32.3636 34.9091" stroke="white"></path>
                                <path d="M28.5754 28.0665L31.6364 30.4464L38.1818 24.3894" stroke="white"></path>
                                <path d="M18.1818 18.5455H26.9091" stroke="white"></path>
                                <path d="M18.1818 22.9092H26.9091" stroke="white"></path>
                                <path d="M18.1818 27.2727L23.6363 27.2727" stroke="white"></path>
                            </svg></Typography>
                        <Typography sx={{textAlign:"center"}}><strong>2. Track your progress</strong></Typography>
                        <Typography sx={{marginTop:"16px"}}>You will automatically be enrolled into Silver tier when you spend £2,500, and will be upgraded to the next tier once your spend meets the next threshold. Keep track of your progress on your NET‑A‑PORTER Rewards hub.</Typography>
                        </Box>
                        <Box className="">
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center",paddingBottom:"10px"}}><svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.8334 24C47.8334 36.9787 37.3121 47.5 24.3334 47.5C11.3547 47.5 0.833374 36.9787 0.833374 24C0.833374 11.0213 11.3547 0.5 24.3334 0.5C37.3121 0.5 47.8334 11.0213 47.8334 24Z" stroke="white"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3673 9H17.8673V9.5V19.1949V19.4835L18.1171 19.6278L23.557 22.7709C19.4931 23.2242 16.3334 26.6711 16.3334 30.8559C16.3334 35.3491 19.9758 38.9915 24.469 38.9915C28.9621 38.9915 32.6046 35.3491 32.6046 30.8559C32.6046 26.6711 29.4448 23.2242 25.3809 22.7709L30.8208 19.6278L31.0707 19.4835V19.1949V9.5V9H30.5707H18.3673ZM18.8673 18.9063V10H20.9181V20.0913L18.8673 18.9063ZM21.9181 20.6691V10H27.0198V20.6691L24.469 22.1429L21.9181 20.6691ZM28.0198 20.0913L30.0707 18.9063V10H28.0198V20.0913ZM31.6046 30.8559C31.6046 34.7968 28.4098 37.9915 24.469 37.9915C20.5281 37.9915 17.3334 34.7968 17.3334 30.8559C17.3334 26.9151 20.5281 23.7203 24.469 23.7203C28.4098 23.7203 31.6046 26.9151 31.6046 30.8559Z" fill="white"></path>
                            </svg></Typography>
                        <Typography sx={{textAlign:"center"}}><strong>3. Unlock even more rewards</strong></Typography>
                        <Typography sx={{marginTop:"16px"}}>The more you shop, the more exclusive benefits you'll receive, from early access to sales and the latest brand collaboration and launches, to priority customer service and your own Personal Shopper.</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Table */}
                <Box sx={{marginTop:"24px"}} className="workListBox">
                    <Box className="haderRow" minWidth={"1135px"}>
                        <Box minWidth={"45%"} className="Tabelsel navFsel templatehead">
                        <Typography>NET-A-PORTER REWARDS TIER</Typography>
                        </Box>
                        <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"15%"}>
                        <Typography>Silver</Typography>
                        </Box>
                        <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"15%"}>
                        <Typography>Gold</Typography>
                        </Box>
                        <Box
                        className="Tabelsel statusSel Tabelcentersel"
                        minWidth={"16%"}
                        >
                        <Typography>Platinum</Typography>
                        </Box>
                    </Box>
                        <Box className="TabelRow" minWidth={"1135px"}>
                        <Box minWidth={"45%"} className="Tabelsel navFsel navtable">
                            <Box>
                                <Typography 
                                    onClick={() => setFirstRow(!firstRow)} 
                                    sx={{ fontSize: "13px !important", marginBottom: '4px', cursor: "pointer", display: 'flex', alignItems: 'center' }}
                                >
                                    WELCOME REWARD
                                    <Typography 
                                        onClick={toggleRotation} 
                                        style={{ cursor: 'pointer', marginLeft: '8px', display: 'inline-block' }} // Adjust margin for spacing
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                transition: 'transform 0.3s',
                                                transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                                                color: 'white' // Ensure the arrow is white
                                            }}
                                        >
                                            <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
                                        </svg>
                                    </Typography>
                                </Typography>
                                <Typography sx={{ fontSize: "12px !important", display: firstRow ? "flex" : "none" }}>
                                    As one of our top customers, you can expect regular discounts and offers.
                                </Typography>
                            </Box>
                        </Box>

                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                                <Box className="g lr ls lt lu lv">
                                    <Box class="z lw lx ly lz hf ma mb"></Box>
                                </Box>
                           
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"18%"}
                            >
                            
                            <Box class="g mc ls lt lu lv">
                                <Box class="z lw lx ly lz hf ma mb"></Box>
                            </Box>
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                            <Box class="g md ls lt lu lv"><Box class="z lw lx ly lz hf ma mb"></Box></Box>
                            </Box>
                        </Box>

                        <Box className="TabelRow" minWidth={"1135px"}>
                        <Box minWidth={"45%"} className="Tabelsel navFsel navtable">
                            <Box>
                                <Typography 
                                    onClick={() => setSecondRow(!secondRow)} 
                                    sx={{ fontSize: "13px !important", marginBottom: '4px', cursor: "pointer", display: 'flex', alignItems: 'center' }}
                                >
                                    BIRTHDAY REWARD
                                    <Typography 
                                        onClick={toggleRotation2} 
                                        style={{ cursor: 'pointer', marginLeft: '8px', display: 'inline-block' }} // Adjust margin for spacing
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                                transition: 'transform 0.3s',
                                                transform: isRotated2 ? 'rotate(180deg)' : 'rotate(0deg)',
                                                color: 'white' // Ensure the arrow is white
                                            }}
                                        >
                                            <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
                                        </svg>
                                    </Typography>
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px !important", 
                                        display: secondRow ? "flex" : "none",
                                        width: "100%",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                    }}
                                    >
                                    Add your date of birth to your Account to receive a birthday gift* on your special day. If you do not receive our email please contact customer care
                                    </Typography>
                            </Box>
                        </Box>

                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                                <Box className="g lr ls lt lu lv">
                                    <Box class="z lw lx ly lz hf ma mb"></Box>
                                </Box>
                           
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"18%"}
                            >
                            
                            <Box class="g mc ls lt lu lv">
                                <Box class="z lw lx ly lz hf ma mb"></Box>
                            </Box>
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                            <Box class="g md ls lt lu lv"><Box class="z lw lx ly lz hf ma mb"></Box></Box>
                            </Box>
                        </Box>
                        <Box className="TabelRow" minWidth={"1135px"}>
                            <Box minWidth={"45%"} className="Tabelsel navFsel navtable">
                    
                            <Typography sx={{fontSize:"13px !important"}}> EXCLUSIVE DISCOUNTS</Typography>
                            </Box>

                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                                <Box className="g lr ls lt lu lv">
                                    <Box class="z lw lx ly lz hf ma mb"></Box>
                                </Box>
                           
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"18%"}
                            >
                            
                            <Box class="g mc ls lt lu lv">
                                <Box class="z lw lx ly lz hf ma mb"></Box>
                            </Box>
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                            <Box class="g md ls lt lu lv"><Box class="z lw lx ly lz hf ma mb"></Box></Box>
                            </Box>
                        </Box>
                        <Box className="TabelRow" minWidth={"1135px"}>
                            <Box minWidth={"45%"} className="Tabelsel navFsel navtable">
                    
                            <Typography sx={{fontSize:"13px !important"}}> PRIVATE SALES</Typography>
                            </Box>

                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                                <Box className="g lr ls lt lu lv">
                                    <Box class="z lw lx ly lz hf ma mb"></Box>
                                </Box>
                           
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"18%"}
                            >
                            
                            <Box class="g mc ls lt lu lv">
                                <Box class="z lw lx ly lz hf ma mb"></Box>
                            </Box>
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                            <Box class="g md ls lt lu lv"><Box class="z lw lx ly lz hf ma mb"></Box></Box>
                            </Box>
                        </Box>
                        <Box className="TabelRow" minWidth={"1135px"}>
                            <Box minWidth={"45%"} className="Tabelsel navFsel navtable">
                    
                            <Typography sx={{fontSize:"13px !important"}}> SHIPPING REWARDS</Typography>
                            </Box>

                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                                {/* <Box className="g lr ls lt lu lv">
                                    <Box class="z lw lx ly lz hf ma mb"></Box>
                                </Box> */}
                           
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"18%"}
                            >
                            
                            <Box class="g mc ls lt lu lv">
                                <Box class="z lw lx ly lz hf ma mb"></Box>
                            </Box>
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                            <Box class="g md ls lt lu lv"><Box class="z lw lx ly lz hf ma mb"></Box></Box>
                            </Box>
                        </Box>
                        <Box className="TabelRow" minWidth={"1135px"}>
                            <Box minWidth={"45%"} className="Tabelsel navFsel navtable">
                    
                            <Typography sx={{fontSize:"13px !important"}}> PRIORITY CUSTOMER CARE</Typography>
                            </Box>

                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                                {/* <Box className="g lr ls lt lu lv">
                                    <Box class="z lw lx ly lz hf ma mb"></Box>
                                </Box> */}
                           
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"18%"}
                            >
                            
                            <Box class="g mc ls lt lu lv">
                                <Box class="z lw lx ly lz hf ma mb"></Box>
                            </Box>
                            </Box>
                            <Box
                            className="Tabelsel statusSel Tabelcentersel"
                            minWidth={"15%"}
                            >
                            <Box class="g md ls lt lu lv"><Box class="z lw lx ly lz hf ma mb"></Box></Box>
                            </Box>
                        </Box>
                        
                    </Box>
                {/* Table */}
                {/* <Box className="RafatextBox">
                    <table>
                        <thead>
                            <tr>
                                <th>NET-A-PORTER REWARDS TIER </th>
                                <th>Pearl</th>
                                <th>Gold</th>
                                <th>Diamond</th>
                            </tr>
                        </thead>
                        <tboday>
                            <tr>
                                <td>
                                    <p>WELCOME REWARD</p>
                                    <p>When you join NET-A-PORTER Rewards or reach a new tier, we’ll treat you to something special – check your inbox for your welcome reward</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>BIRTHDAY REWARD</p>
                                    <p>Add your date of birth to your Account to receive a birthday gift* on your special day. If you do not receive our email please contact customer care</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>EXCLUSIVE DISCOUNTS</p>
                                    <p>As one of our top customers you can expect regular discounts and offers</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>PRIVATE SALES</p>
                                    <p>Get first access to our end-of-season Sales and to our NET-A-PORTER Rewards Private Sales, reserved for our most loyal customers</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>SHIPPING REWARDS</p>
                                    <p>We’ll ship your orders to you free of charge wherever you are in the world</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>PRIORITY CUSTOMER CARE</p>
                                    <p>Get assistance straight away with our 24-hour priority Customer Care service</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>INVITATIONS TO EXCLUSIVE EXPERIENCES</p>
                                    <p>Sign up to our dedicated emails so you don’t miss the opportunity to attend fabulous events hosted by NET-A-PORTER</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>PERSONAL SHOPPER</p>
                                    <p>Reserved for EIPs only, our Personal Shoppers are experts on all things NET-A-PORTER</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>EARLY ACCESS EIP PREVIEWS</p>
                                    <p>See and shop every new arrival before the general public with the EIP Preview</p>
                                </td>
                                <td>
                                    <img style={{ width : "15%" }} src={rightImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                                <td>
                                    <img style={{ width : "5%" }} src={closeImg} alt="" />
                                </td>
                            </tr>
                        </tboday>
                    </table>
                </Box> */}
                {/* Offer Page link */}
                <Box sx={{paddingBottom:"0px !important"}} className="RafatextBox">
                    <Typography className='rafaTBHText' style={{ textAlign : "center" }}>Exclusive Offers Just for You</Typography>
                    <Typography className='rafaTBNText' style={{ textAlign : "center" }}>As an EIP member, you're entitled to special offers and discounts. Explore limited-time deals, personalized promotions, and exclusive savings tailored just for you. Don't miss out on these exclusive opportunities to save!</Typography>
                    <span className="discoverBtn" data-aos="fade" onClick={() => navigate('/offer')}>
                    <Typography>Offers</Typography>
                    <span className="DBLine"></span>
                    <span className="dbsLine dbTops"></span>
                    <span className="dbsLine dbRighs"></span>
                    <span className="dbsLine dbBottoms"></span>
                    <span className="dbsLine dbLefts"></span>
                    </span>
                </Box>
                {/* Offer Page link */}
                <Box className="RafatextBox">
                    <Typography className='rafaTBHText' style={{ textAlign : "center" }}>Meet our Personal Shoppers</Typography>
                    <Typography className='rafaTBNText'>When you reach EIP status, you will be assigned a dedicated Personal Shopper to assist you with anything you might need, from a simple size or for query to sourcing hard-to-find items. Your Personal Shopper will also get in touch to invite you to private sales and exclusive events. </Typography>
                    <Box className="ambCardBox">
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}uploads/people/1723465666834_team-thumb-2.jpg`} />
                        </Box>
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}uploads/people/1723465666834_team-thumb-2.jpg`} />
                        </Box>
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}uploads/people/1723465666834_team-thumb-2.jpg`} />
                        </Box>
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}uploads/people/1723465666834_team-thumb-2.jpg`} />
                        </Box>
                    </Box>
                </Box>

                
                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>
                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                            <Typography>Terms and Conditions</Typography>
                            <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
