import React from 'react'
import "./style.css"

//components
import { Box, Typography } from '@mui/material';
import { Clogo } from "../../Assets/Data";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//images


export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Box className="footer">
      <ToastContainer />
        <Box className="fClogo">{Clogo}</Box>


        <Box className="FfooterBox fMidBox">

          <Box className="fMidInBox">
            <Typography className='fRowHeader'>Our World</Typography>

            <Box className="TextFlipBox" onClick={() => navigate("/our-Stories")}>
              <Typography className='navItemTExt text1'>Our Stories</Typography>
              <Typography className='navItemTExt text2'>Our Stories</Typography>
            </Box>

            <Box className="TextFlipBox" onClick={() => navigate("/people")}>
              <Typography className='navItemTExt text1'>Friends of the Brand</Typography>
              <Typography className='navItemTExt text2'>Friends of the Brand</Typography>
            </Box>

            <Box className="TextFlipBox" onClick={() => navigate("/our-caviar")}>
              <Typography className='navItemTExt text1'>Caviar</Typography>
              <Typography className='navItemTExt text2'>Caviar</Typography>
            </Box>

            <Box className="TextFlipBox" onClick={() => navigate("/our-accessories")}>
              <Typography className='navItemTExt text1'>Accessories / Delicacies</Typography>
              <Typography className='navItemTExt text2'>Accessories / Delicacies</Typography>
            </Box>

            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>Wines</Typography>
              <Typography className='navItemTExt text2'>Wines</Typography>
            </Box>
          </Box>


          <Box className="fMidInBox mobileFBox">
            <Typography className='fRowHeader'></Typography>

            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>The Caviar</Typography>
              <Typography className='navItemTExt text2'>The Caviar</Typography>
            </Box>


            <Box className="TextFlipBox" onClick={() => navigate("/n25")}>
              <Typography className='navItemTExt text1'>N25</Typography>
              <Typography className='navItemTExt text2'>N25</Typography>
            </Box>

            <Box className="TextFlipBox" onClick={() => navigate("/n25-global")}>
              <Typography className='navItemTExt text1'>N25 Global</Typography>
              <Typography className='navItemTExt text2'>N25 Global</Typography>
            </Box>


            <Box className="TextFlipBox" onClick={() => navigate("/contact")}>
              <Typography className='navItemTExt text1'>Contacts</Typography>
              <Typography className='navItemTExt text2'>Contacts</Typography>
            </Box>


            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>Careers</Typography>
              <Typography className='navItemTExt text2'>Careers</Typography>
            </Box>
          </Box>

          <Box className="fMidInBox deskTopBox">
            <Typography className='fRowHeader' sx={{ color: "#ffffff00" }}>.</Typography>

            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>The Caviar</Typography>
              <Typography className='navItemTExt text2'>The Caviar</Typography>
            </Box>


            <Box className="TextFlipBox" onClick={() => navigate("/n25")}>
              <Typography className='navItemTExt text1'>N25</Typography>
              <Typography className='navItemTExt text2'>N25</Typography>
            </Box>

            <Box className="TextFlipBox" onClick={() => navigate("/n25-global")}>
              <Typography className='navItemTExt text1'>N25 Global</Typography>
              <Typography className='navItemTExt text2'>N25 Global</Typography>
            </Box>


            <Box className="TextFlipBox" onClick={() => navigate("/contact")}>
              <Typography className='navItemTExt text1'>Contacts</Typography>
              <Typography className='navItemTExt text2'>Contacts</Typography>
            </Box>


            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>Careers</Typography>
              <Typography className='navItemTExt text2'>Careers</Typography>
            </Box>
          </Box>


          <Box className="fMidInBox">
            <Typography className='fRowHeader'>Legal</Typography>

            <Box className="TextFlipBox" onClick={() => navigate("/terms-and-conditions")}>
              <Typography className='navItemTExt text1'>Terms and conditions</Typography>
              <Typography className='navItemTExt text2'>Terms and conditions</Typography>
            </Box>


            <Box className="TextFlipBox" onClick={() => navigate("/privacy-policy")}>
              <Typography className='navItemTExt text1'>Privacy policy</Typography>
              <Typography className='navItemTExt text2'>Privacy policy</Typography>
            </Box>


            <Box className="TextFlipBox" onClick={() => navigate("/shipping-delivery-information")}>
              <Typography className='navItemTExt text1'>Shipping Information</Typography>
              <Typography className='navItemTExt text2'>Shipping Information</Typography>
            </Box>
            <Box className="TextFlipBox" onClick={() => navigate("/loyalty-rewards")}>
              <Typography className='navItemTExt text1'>Customer Loyalty Program</Typography>
              <Typography className='navItemTExt text2'>Customer Loyalty Program</Typography>
            </Box>
            <Box className="TextFlipBox" onClick={() => navigate("/offer")}>
              <Typography className='navItemTExt text1'>Offer</Typography>
              <Typography className='navItemTExt text2'>Offer</Typography>
            </Box>
          </Box>

          <Box className="fMidInBox"></Box>

          <Box className="fMidInBox rightSTab">
            <Typography className='fRowHeader'>Social</Typography>

            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>Instagram</Typography>
              <Typography className='navItemTExt text2'>Instagram</Typography>
            </Box>
          </Box>
        </Box>

        <Box className="fLastBoxMobile">
          <Box className="fbmRow">
            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>Go to EU shop</Typography>
              <Typography className='navItemTExt text2'>Go to EU shop</Typography>
            </Box>
          </Box>

          <Box className="fbmRow">
            <Typography>©2024 N25.</Typography>
            <Typography>All rights reserved.</Typography>
          </Box>
        </Box>


        <Box className="fLastBox">
          <Box className="fLastInBox">
            <Typography>©2024 N25.</Typography>
          </Box>

          <Box className="fLastInBox">
            <Typography>All rights reserved.</Typography>
          </Box>

          <Box className="fLastInBox"></Box>
          <Box className="fLastInBox"></Box>
          <Box className="fLastInBox rightSTab">
            <Box className="TextFlipBox">
              <Typography className='navItemTExt text1'>Go to EU shop</Typography>
              <Typography className='navItemTExt text2'>Go to EU shop</Typography>
            </Box>
          </Box>
        </Box>

      </Box>
    </>
  )
}
