import React, { useEffect, useState } from 'react';
import { useNavigate} from "react-router-dom";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, colors, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import "./style.css";
import { TOAST_CONFIG } from "../../lib/constants";

export default function SignupPage({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [Fcheck, setFCheck] = useState(false);
    const [Scheck, setSCheck] = useState(false);
    const [firstSection, setFirstSection] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const initialSignupState = {
        first_name: "",
        last_name: "",
        email: "",
        phone:"",
        password:"",
        status: "Active",
      };
    
      const [signupData, setSignupData] = useState(initialSignupState);

      const validationObj = {
        hasErr: false,
        first_name: {
          error: false,
          msg: "",
        },
        last_name: {
          error: false,
          msg: "",
        },
        email: {
            error: false,
            msg: "",
          },
          phone: {
            error: false,
            msg: "",
          },
          password: {
            error: false,
            msg: "",
          },
      };
    
      const [validationErrors, setValidationErrors] = useState({
        ...validationObj,
      });
    
      const handleValidation = (formInput) => {
        formInput = formInput || signupData;
        let validationerr = { ...validationObj };
    
        if (formInput.first_name == "") {
          validationerr.hasErr = true;
          validationerr.first_name = {
            error: true,
            msg: "First Name is required",
          };
        }
        if (formInput.last_name == "") {
            validationerr.hasErr = true;
            validationerr.last_name = {
              error: true,
              msg: "Last Name is required",
            };
          }
          if (formInput.email == "") {
            validationerr.hasErr = true;
            validationerr.email = {
              error: true,
              msg: "Email is required",
            };
          }
          else if (!/\S+@\S+\.\S+/.test(formInput.email)) {
            // Basic email format validation regex
            validationerr.hasErr = true;
            validationerr.email = {
              error: true,
              msg: "Please enter a valid email address",
            };
          }
          if (formInput.phone == "") {
            validationerr.hasErr = true;
            validationerr.phone = {
              error: true,
              msg: "Phone is required",
            };
          }
          else if (!/^\d+$/.test(formInput.phone)) {
            // Ensure that phone number contains only digits
            validationerr.hasErr = true;
            validationerr.phone = {
              error: true,
              msg: "Please enter a valid phone number (only digits)",
            };
          }
          if (formInput.password == "") {
            validationerr.hasErr = true;
            validationerr.password = {
              error: true,
              msg: "Password is required",
            };
          }
    
        setValidationErrors(validationerr);
        if (validationerr.hasErr) {
          return false;
        }
        return true;
      };
    
      const setFormData = (e) => {
        let newState = {
          ...signupData,
          [e.target.name]: e.target.value,
        };
        setSignupData(newState);
        if (validationErrors.hasErr) {
          handleValidation(newState);
        }
      };

      let saveSignup = async () => {
        if (!handleValidation()) {
          toast.error("Correct form data!", TOAST_CONFIG);
          return false;
        }
        if (signupData.password !== signupData.confirm_password) {
          // const notifys = () => toast.error("Passwords do not match");
          // setNotify(notifys);
          toast.error("Passwords do not match", TOAST_CONFIG);
        }
        console.log("Final Submit @@ = ",signupData);
        setLoading(true);
        if (
          signupData.email !== "" &&
          signupData.password !== "" &&
          signupData.confirm_password !== "" &&
          signupData.password === signupData.confirm_password
        ) {
        axios
          .post(`${BACKEND_URL}/api/V1/register`, signupData)
          .then((response) => {
            console.log("response @ = ",response.data.result.message);
              if (response.data.status) {
                  // const notifys = () => toast.success(response.data.result.message);
                  // setNotify(notifys);
                  toast.success(response.data.result.message, TOAST_CONFIG);
                  sessionStorage.setItem(
                    "User",
                    JSON.stringify(response.data.result.user)
                  ); 
                  localStorage.setItem("Token", response.data.result.token);
                  setTimeout(() => {
                    navigate("/profile");
                }, 3000);
              } else {
                  // const notifys = () => toast.error(response.data.errors);
                  // setNotify(notifys);
                  toast.error(response.data.errors, TOAST_CONFIG);
              }
          })
          .catch((error) => {
              // const notifys = () => toast.error("Error fetching data");
              // setNotify(notifys);
              toast.error("Error fetching data", TOAST_CONFIG);
              console.error("Error fetching data:", error);
              // setErrorMessage("Unable to register");
          });
        }
      };

      const redirectLogin = () => {
        navigate("/login");
      }

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
            
                <Box className="contactInputSection">
                   
                            <Box className="contactInfo">
                            <Typography className='textInfo1'>Signup</Typography>
                            </Box>
                       

                    <Box className="contactInputBox">
                        <Box className="contactFInBox">
                            <Box className="contactAddressBox">
                                <Typography className="inputLabel">FIRST NAME</Typography>
                                <input type="text" className='addressinput' placeholder='Your First Name' name="first_name"
                      value={signupData.first_name ? signupData.first_name : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.first_name.msg}</span>

                                <Typography className="inputLabel">EMAIL</Typography>
                                <input type="text" className='addressinput' placeholder='Your Email' name="email"
                      value={signupData.email ? signupData.email : "" }
                      onChange={setFormData} />
                                <span className="validationerr">{validationErrors.email.msg}</span>
                            </Box>

                            <Box className="contactAddressBox">
                                <Typography className="inputLabel">LAST NAME</Typography>
                                <input type="text" className='addressinput' placeholder='Your Last Name*' name="last_name"
                      value={signupData.last_name ? signupData.last_name : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.last_name.msg}</span>

                                <Typography className="inputLabel">YOUR PHONE NUMBER</Typography>
                                <input type="text" className='addressinput' placeholder='Your phone number' name="phone"
                      value={signupData.phone ? signupData.phone : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.phone.msg}</span>
                            </Box>
                            
                            
                        </Box>

                        <Box className="contactFInBox">
                            <Box className="contactAddressBox">
                                <Typography className="inputLabel">PASSWORD</Typography>
                                <input type="text" className='addressinput' placeholder='Password*' name="password"
                      value={signupData.password ? signupData.password : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.password.msg}</span>

                                <Typography className="inputLabel">CONFIRM PASSWORD</Typography>
                                <input type="text" className='addressinput' placeholder='Confirm Password*' name="confirm_password" value={signupData.confirm_password ? signupData.confirm_password : "" }
                      onChange={setFormData} />
                            </Box>
                        </Box>

                        <Box className="inputMessageBox">
                            <Box className="MailCheckBox MailCheckBoxL">
                                
                                <Typography>Already have account? <span style={{cursor:"pointer",color:"#4f33d1"}} onClick={() =>redirectLogin()}>Login</span></Typography>
                            </Box>

                            <span className='discoverBtn' onClick={() =>saveSignup()} >
                                <Typography>Signup</Typography>
                                <span className='dbsLine dbTops'></span>
                                <span className='dbsLine dbRighs'></span>
                                <span className='dbsLine dbBottoms'></span>
                                <span className='dbsLine dbLefts'></span>
                            </span>
                        </Box>
                    </Box>
                </Box>

                {/* mail section */}
                <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}
