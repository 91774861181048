import React, { useState, useEffect } from 'react';
import "./style.css";

// import roterImg from "../../Assets/Images/roter-removebg-preview.png";
import roterImg from "../../Assets/Images/roterIcon.svg";

export default function RoundLogo() {
  const [rotation, setRotation] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollDelta = currentScrollY - lastScrollY;
      setRotation((prevRotation) => prevRotation + scrollDelta * 0.1);
      setLastScrollY(currentScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);
  return (
    <>
      <div className="RoundlogoBox" style={{ transform: `rotate(${rotation}deg)` }}>
        <img src={roterImg} />
      </div>
    </>
  )
}
