import React, { useState } from "react";
import "./style.css";

//components
import CookiePop from "../../Components/CookiePop";
import { Box, Typography } from "@mui/material";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import { whiteArrow } from "../../Assets/Data";

//images
import blogImg1 from "../../Assets/Images/blogImg.jpg";
import blogImg2 from "../../Assets/Images/blogImg2.webp";
import productImg1 from "../../Assets/Images/product1.png";
import productImg2 from "../../Assets/Images/product2.png";
import productImg3 from "../../Assets/Images/product3.png";

export default function Blogs({ cookiePop, setCookiePop }) {
  const [check, setCheck] = useState(false);

  const productCard = ({ img, bText1, bText2 }) => {
    return (
      <Box className="productCard">
        <Box className="productImgBox">
          <img src={img} />
        </Box>
        <Box className="shopSLabelTextBox" data-aos="fade-up">
          <span>{bText1}</span>
          <span className="ssltbLine"></span>
        </Box>
        <Typography className="pCardBText" data-aos="fade-up">
          {bText2}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />
      <Box className="blogPage">
        <Box className="TopHeaderBox">
          <Typography className="thbHeader">
            Women's Hour: Alison Wallace
          </Typography>
          <Typography className="thbSummery">
            Alison Wallace is a senior leader in the not-for-profit sector,
            serving as the CEO of SOS Children's Villages UK - a global
            organisation dedicated to helping at-risk children and one that
            DeMellier has a long standing partnership with via our 'A Bag, A
            Life' initiative. Originally from New Zealand, Alison credits her
            parents, who were active in the anti-apartheid movement in the
            1980’s, for inspiring her strong commitment to political and social
            justice campaigns. Currently based in London, we spoke to Alison
            about her passion for helping others and her highlights of working
            in the charity sector.
          </Typography>
        </Box>

        <Box className="blogImgBox">
          <Box className="blogImg">
            <img src={blogImg1} />
          </Box>
          <Box className="blogText">
            <Typography className="thbHeaderSummery">
              Please tell us a little bit about yourself.
            </Typography>
            <Typography className="thbSummery">
              I am originally from New Zealand and have since settled here in
              the UK. For work, I am Chief Executive of SOS Children’s Villages
              UK. I’m also a trustee at Crisis, a charity for people
              experiencing homelessness.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              Please explain a bit about the SOS Children’s Villages background
              and the important work that you do?
            </Typography>
            <Typography className="thbSummery">
              SOS Children's Villages is a worldwide organisation dedicated to
              ensuring children have the bonds they need to grow up feeling
              safe, loved and respected, which is vital to every child’s start
              in life. We know what a difference a loving family environment,
              going to school, good health care and a job make to everyone's
              life chances, and we work alongside some of the poorest and most
              vulnerable children, their families and communities to help make
              that happen.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              How does working for SOS Children’s Villages relate to you and
              your personal story?
            </Typography>
            <Typography className="thbSummery">
              I’m originally from New Zealand. I’m incredibly grateful that I
              was born in a time and come from a country where I could take
              advantage of the opportunities that were there: education, health,
              employment, peace, stability. My parents were politically engaged
              enough that I grew up being aware of the wider world around this
              tiny country on the other side of the planet and, crucially, that
              we are all connected to everyone, everywhere. My first experience
              of that was taking part in anti-apartheid protests in the 1980’s,
              as a teenager. It’s gone on to inspire my life at work and my
              activism at home. And given what’s happening in our world right
              now, it’s more important to me than ever.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              What do you love most about your job?
            </Typography>
            <Typography className="thbSummery">
              The thing I most love about my job is the quite extraordinary
              women I have the privilege of meeting. Their stories and their
              work make it all worthwhile. At a recent international meeting of
              all the SOS Children’s Villages members from around the world, I
              had the opportunity to speak with the head of SOS Children’s
              Villages in Sierra Leone, Sophie Ndong. Sierra Leone has some of
              the highest rates of sexual and gender-based violence in the
              world, weak national institutions for holding offenders to account
              and a health system that has never recovered from the 10 year
              civil war in the 1990’s. Sophie and her team are working day in
              and day out to protect girls and young women in the SOS Children’s
              Villages programmes, empower them with the knowledge and tools to
              protect themselves and act with them when holding perpetrators to
              account. She is resolute in the face of what sometimes looks like
              overwhelming odds but her belief in change is powerful.
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
        </Box>

        <Box className="atSosTextBox">
          <Typography className="atSosText">
            At SOS Children’s Villages, the impact of most of our programmes
            comes through the agency of women, whether that’s women as
            educators, health care practitioners, carers or entrepreneurs.
          </Typography>
        </Box>

        <Box className="blogImgBox blogImgBox2">
          <Box className="blogText">
            <Typography className="thbHeaderSummery">
              What has been your proudest moment as a woman in your career?
            </Typography>
            <Typography className="thbSummery">
              At SOS Children’s Villages, the impact of most of our programmes
              comes through the agency of women, whether that’s women as
              educators, health care practitioners, carers or entrepreneurs.
              Women are often the most active and vocal in demanding change in
              communities even though the power structures around them
              frequently dilute or disenfranchise their voices. A couple of
              years ago, I was privileged enough to meet a group of women in
              Chipata, in Zambia, who between them run a flour-milling business.
              They’ve been able to make the availability of flour for local
              families more reliable and accessible, run a small mutual credit
              scheme and generate enough excess income to expand their business,
              albeit slowly. We talked about the challenges of running a
              business as a committee. Someone mentioned that they’d had to
              remove two committee members who hadn’t played a sufficiently
              active role. It turned out those two members were the only men on
              the committee, and we all had a really good laugh. It was a real
              moment of connection, and of seeing women take their power even in
              this small way. I was proud of how we had supported the group to
              grow their business, but also humbled by the example of where and
              how change truly comes about.{" "}
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              What makes a successful female leader in business?
            </Typography>
            <Typography className="thbSummery">
              The single most important thing to my success has been working
              with good people. I think we have a much more nuanced definition
              of success and good leadership than we used to. It’s no longer all
              about the superstar lone wolf whose organisation loses its way the
              minute he or she leaves. The lasting positive change you make,
              your legacy if you like, is what defines your success. And if
              you’re going to do that, you need good people empowered to do good
              jobs. You really are only as good as the people you work with.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              How do you balance your work and personal life?{" "}
            </Typography>
            <Typography className="thbSummery">
              We have a great team at SOS Children’s Villages, built on trust
              that everyone does what they need to do to get good outcomes,
              accountability for those outcomes and clear lines of
              communication. That means that I can be confident that I’m pulled
              in at the right time for the right things, and that I can step
              away at the end of the working week or to go on holiday and know
              that everything is working the way it should. As an organisation,
              we also encourage people to be clear about times when what they
              need to do outside work – whether family or other commitments or
              simply the need to be somewhere else for whatever reason – means
              they can’t balance work and personal life at that particular time.
              We do our utmost to be flexible and support people to get work and
              personal life back into a balance that’s right for us both.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              What have been some highlights during the partnership with
              DeMellier?
            </Typography>
            <Typography className="thbSummery">
              DeMellier is one of SOS Children’s Villages UK’s longest standing
              corporate partners, supporting us since their inception. The
              partnership has evolved over that time and the amount donated to
              us has grown hugely each year. DeMellier has remained unwaveringly
              dedicated, and the ‘A Bag, A Life’ initiative has remained a
              strong and constant message throughout. The lives of thousands of
              children and young people across Africa, from Somalia, Zambia,
              Liberia and now Senegal have been impacted.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbSummery">
              The Seven Women, Seven Causes campaign was a particular highlight.
              Mireia chose SOS Children’s Villages as particularly close to her
              heart to benefit from the sales of her LIFE tote.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbHeaderSummery">
              Describe DeMellier in one word.
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="thbSummery">
              Purpose. Which is why DeMellier and SOS Children’s Villages have
              such a strong partnership.
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
          <Box className="blogImg">
            <img src={blogImg2} />
          </Box>
        </Box>

        <Box className="ProductList">
          <Box className="productHeaderBox">
            <Typography className="ambHeader">All Products</Typography>
            <Typography className="productPageSuhHeader">
              Shop now and get the finest Caviar delivered to your home.
            </Typography>
            <Typography className="productPageSuhHeader">
              Choose between N25 Caviar and carefully curated delicacies, wines
              and accessories.
            </Typography>
          </Box>

          <Box className="productListBox">
            {productCard({
              img: productImg1,
              bText1: "DISCOVER",
              bText2: "N25 Caviar",
            })}
            {productCard({
              img: productImg2,
              bText1: "DISCOVER",
              bText2: "Accessories/Delicacies",
            })}
            {productCard({
              img: productImg3,
              bText1: "DISCOVER",
              bText2: "Wines / Champagne",
            })}
          </Box>
        </Box>

        {/* mail section */}
        <Box className="mailSection">
          <Typography className="ambHeader">
            Join our <span className="TimesNowText">community</span>
          </Typography>
          <Typography className="summery14">
            Sign up and stay up to date on product launches and pre order. By
            entering your email address you agree to our Terms and Conditions
            and Privacy and Cookie Policy.{" "}
          </Typography>

          <Box className="mailInputBox">
            <input type="email" name="email" placeholder="Your email*" />
            <Box className="submitBtn">{whiteArrow}</Box>
          </Box>

          <Box className="MailCheckBox">
            <Box
              className={check ? "check checkActive" : "check"}
              onClick={() => setCheck(!check)}
            ></Box>
            <Typography>Please click here to accept our</Typography>
            <Box className="termsbox">
              <Typography>Terms and Conditions</Typography>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </>
  );
}
