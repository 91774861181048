import React, { useState } from "react";
import "./style.css";

//components
import CookiePop from "../../Components/CookiePop";
import { Box, Typography } from "@mui/material";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import { whiteArrow } from "../../Assets/Data";

//images
import productImg1 from "../../Assets/Images/product1.png";
import productImg2 from "../../Assets/Images/product2.png";
import productImg3 from "../../Assets/Images/product3.png";
import parisImg1 from "../../Assets/Images/PARIS-01.jpg";
import parisImg2 from "../../Assets/Images/PARIS-02.jpg";
import b2Img1 from "../../Assets/Images/b2Img1.webp";
import b2Img2 from "../../Assets/Images/b2Img2.webp";
import b2Img3 from "../../Assets/Images/b2Img3.webp";
import b2Img4 from "../../Assets/Images/b2Img4.webp";
import b2Img5 from "../../Assets/Images/b2Img5.webp";

export default function Blogs2({ cookiePop, setCookiePop }) {
  const [check, setCheck] = useState(false);

  const productCard = ({ img, bText1, bText2 }) => {
    return (
      <Box className="productCard">
        <Box className="productImgBox">
          <img src={img} />
        </Box>
        <Box className="shopSLabelTextBox" data-aos="fade-up">
          <span>{bText1}</span>
          <span className="ssltbLine"></span>
        </Box>
        <Typography className="pCardBText" data-aos="fade-up">
          {bText2}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />
      <Box className="blogPage blog2Page">
        <Box className="TopHeaderBox">
          <Typography className="btPHeader">
            A CONSIDERED GUIDE TO PARIS
          </Typography>
          <Typography className="btPSummery">
            Our new Considered Guide series showcases our favourite cities
            through our eyes. We’ll be sharing places that echo the values,
            taste and spirit of DeMellier: from sustainable shopping experiences
            to female-led businesses and design-led destinations. First up,
            France’s cosmopolitan capital, Paris.
          </Typography>
        </Box>

        <Box className="BigImgBox">
          <img src={parisImg1} className="desk" />
          <img src={parisImg2} className="mobe" />
        </Box>

        <Box className="blogImgBox blogImgBox2">
          <Box className="blogText">
            <Typography className="btPHeader">
              A PLACE <br /> FOR CULTURE
            </Typography>
            <Typography className="btPSummery">GALERIE 54</Typography>
            <Typography className="btPSummery">
              10 Rue Mallet-Stevens, 75016 Paris
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="btPSummery">
              A beacon of exclusivity and meticulous taste, Galerie 54 is an
              appointment-only 1920s townhouse and art space. Regarded as a
              modernist gem, it was the former home of sculptors Jan and Joël
              Martel, designed by architect Robert Mallet-Stevens. Owned and
              curated now by Parisian art dealer Éric Touchaleaume.
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
          <Box className="blogImg">
            <img src={b2Img1} />
          </Box>
        </Box>

        <Box className="blogImgBox">
          <Box className="blogImg">
            <img src={b2Img2} />
          </Box>
          <Box className="blogText">
            <Typography className="btPHeader">
              A PLACE <br /> FOR DINING
            </Typography>
            <Typography className="btPSummery">ESPADON, THE RITZ</Typography>
            <Typography className="btPSummery">
              15 Place Vendôme, 75001 Paris
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="btPSummery">
              In 2023, the iconic Ritz Paris opened a new restaurant, led by
              talented Michelin-starred chef Eugénie Béziat: the first time a
              female head chef had been appointed there. Dual influences of
              Gabonese heritage and classic French cookery inspire her menu. A
              unique gastronomic experience overlooking the hotel's Grand
              Jardin.
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
        </Box>

        <Box className="blogImgBox blogImgBox2">
          <Box className="blogText">
            <Typography className="btPHeader">
              A PLACE <br /> FOR SHOPPING
            </Typography>
            <Typography className="btPSummery">
              SAINT-OUEN FLEA MARKET
            </Typography>
            <Typography className="btPSummery">
              Rue des Rosiers, 93400 Saint Ouen France
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="btPSummery">
              The world’s largest antiques market is in Paris and a perfect
              weekend destination to find unique, yet to be rediscovered gems:
              including leather-bound novels, jewellery, vintage pieces,
              one-of-a-kind trinkets and much, much more. A true Parisian
              experience and one that reinforces our belief in circularity and
              timelessness.{" "}
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
          <Box className="blogImg">
            <img src={b2Img3} />
          </Box>
        </Box>

        <Box className="blogImgBox">
          <Box className="blogImg">
            <img src={b2Img4} />
          </Box>
          <Box className="blogText">
            <Typography className="btPHeader">
              A PLACE <br /> FOR OVERNIGHT
            </Typography>
            <Typography className="btPSummery">CHÂTEAU VOLTAIRE</Typography>
            <Typography className="btPSummery">
              55 Rue Saint-Roch, 75001 Paris
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="btPSummery">
              A destination for stylish clientele - French and international -
              this elegant hideaway offers a boutique experience close to the
              Tuileries Garden. Created by Thierry Gillier, founder of luxury
              fashion House Zadig & Voltaire, Château Voltaire is a go-to for
              dinner, drinks and a chic overnight stay in the heart of Paris.{" "}
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
        </Box>

        <Box className="blogImgBox blogImgBox2">
          <Box className="blogText">
            <Typography className="btPHeader">
              A PLACE TO <br /> FEEL LOCAL
            </Typography>
            <Typography className="btPSummery">PROMENADE PLANTÉE</Typography>
            <Typography className="btPSummery">
              Coulée Verte René-Dumont, 75012 Paris
            </Typography>
            <Box py={1.5}></Box>
            <Typography className="btPSummery">
              Promenade Plantée is a partially elevated parkway and promenade
              built along an abandoned rail line and viaduct. This 4.7km long
              green space is a beautiful, surprisingly under-the-radar way to
              see Paris. The path also featured in the classic 2004 romantic
              drama Before Sunset starring Ethan Hawke and Julie Delpy
            </Typography>
            <Box className="garethTBox">
              <span className="discoverBtn">
                <Box className="shopSLabelTextBox">
                  <span>Shop Gareth's Choice </span>
                  <span className="ssltbLine"></span>
                </Box>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
          <Box className="blogImg">
            <img src={b2Img5} />
          </Box>
        </Box>

        <Box className="ProductList">
          <Box className="productHeaderBox">
            <Typography className="ambHeader">All Products</Typography>
            <Typography className="productPageSuhHeader">
              Shop now and get the finest Caviar delivered to your home.
            </Typography>
            <Typography className="productPageSuhHeader">
              Choose between N25 Caviar and carefully curated delicacies, wines
              and accessories.
            </Typography>
          </Box>

          <Box className="productListBox">
            {productCard({
              img: productImg1,
              bText1: "DISCOVER",
              bText2: "N25 Caviar",
            })}
            {productCard({
              img: productImg2,
              bText1: "DISCOVER",
              bText2: "Accessories/Delicacies",
            })}
            {productCard({
              img: productImg3,
              bText1: "DISCOVER",
              bText2: "Wines / Champagne",
            })}
          </Box>
        </Box>

        {/* mail section */}
        <Box className="mailSection">
          <Typography className="ambHeader">
            Join our <span className="TimesNowText">community</span>
          </Typography>
          <Typography className="summery14">
            Sign up and stay up to date on product launches and pre order. By
            entering your email address you agree to our Terms and Conditions
            and Privacy and Cookie Policy.{" "}
          </Typography>

          <Box className="mailInputBox">
            <input type="email" name="email" placeholder="Your email*" />
            <Box className="submitBtn">{whiteArrow}</Box>
          </Box>

          <Box className="MailCheckBox">
            <Box
              className={check ? "check checkActive" : "check"}
              onClick={() => setCheck(!check)}
            ></Box>
            <Typography>Please click here to accept our</Typography>
            <Box className="termsbox">
              <Typography>Terms and Conditions</Typography>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </>
  );
}
