import React, { useEffect, useState } from 'react';
import "./style.css";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow, StoriesData } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';

//images
import stImg1 from "../../Assets/Images/stImg1.webp"
import stImg2 from "../../Assets/Images/stImg2.webp"
import stImg3 from "../../Assets/Images/stImg3.webp"
import stImg4 from "../../Assets/Images/stImg4.jpg"
import stImg5 from "../../Assets/Images/stImg5.webp"
import stImg6 from "../../Assets/Images/stImg6.webp"
import stImg7 from "../../Assets/Images/stImg7.webp"
import stImg8 from "../../Assets/Images/stImg8.jpg"
import stImg9 from "../../Assets/Images/stImg9.webp"
import stImg10 from "../../Assets/Images/stImg10.webp"
import stImg11 from "../../Assets/Images/stImg11.jpg"
import stImg12 from "../../Assets/Images/stImg12.webp"
import stImg13 from "../../Assets/Images/stImg13.webp"
import stImg14 from "../../Assets/Images/stImg14.webp"
import stImg15 from "../../Assets/Images/stImg15.webp"
import stImg16 from "../../Assets/Images/stImg16.webp"
import stImg17 from "../../Assets/Images/stImg17.webp"
import stImg18 from "../../Assets/Images/stImg18.webp"
import stImg19 from "../../Assets/Images/stImg19.jpg"
import stImg20 from "../../Assets/Images/stImg20.webp"
import stImg21 from "../../Assets/Images/stImg21.jpg"
import stImg22 from "../../Assets/Images/stImg22.webp"
import stImg23 from "../../Assets/Images/stImg23.webp"
import stImg24 from "../../Assets/Images/stImg24.webp"

export default function Stories({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [firstSection, setFirstSection] = useState([]);
    const [stories, setStories] = useState([]);

    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/stories/content`)
        .then((response) => {
            setFirstSection(response.data.result.content);            
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);
    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/stories/list`)
        .then((response) => {
            setStories(response.data.result.stories);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    const storiesCard = ({ img, date, title, key, disibal }) => {
        return (
            <>
                {
                    disibal ?
                    <Box className="storiesCard deskTopCard"></Box> :
                    <Box className="storiesCard" key={key}>
                        <img src={img} />
                        <Box className="shopSLabelTextBox" data-aos="fade-up">
                            <span>{date}</span>
                            <span className='ssltbLine'></span>
                        </Box>
                        <Typography className='storiesText2'>{title}</Typography>
                    </Box>
                }
            </>
        )
    }
    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="storiesPage">
                {firstSection.map((item) => { 
                    return(
                        <Box className="stBox1">
                            <Typography className='stHeader'>{item.title}</Typography>
                            <Typography className='stSubHeader'>{item.sub_title}</Typography>
                        </Box>
                    )
                })}
                <Box className="stImgBox1">
                    {stories.map((item) => {
                        return(
                            <Box className="stimgInBox">
                                <img src={`${BACKEND_URL}${item.story_image_1}`} />
                                <Typography className='stCardTitle'>{item.title}</Typography>
                                <Typography className='stCardSummery'>{item.short_description}</Typography>
                                <Typography className='readMoreText'>Read more</Typography>
                            </Box>
                        )
                    })}
                </Box>
                {/*<Box className="stImgBox1">
                    <Box className="stimgInBox">
                        <img src={stImg1} />
                        <Typography className='stCardTitle'>Our Autumn Winter 2024 Film</Typography>
                        <Typography className='stCardSummery'>Bathed in warm cinematic neon, we present our new film for Autumn Winter 2024. In this dynamic collection, powerful feminine silhouettes take centre stage.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox">
                        <img src={stImg2} />
                        <Typography className='stCardTitle'>Our Autumn Winter 2024 Film</Typography>
                        <Typography className='stCardSummery'>Bathed in warm cinematic neon, we present our new film for Autumn Winter 2024. In this dynamic collection, powerful feminine silhouettes take centre stage.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>

                <Box className="stImgBox2">
                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg3} />
                        <Typography className='stCardTitle'>Southern Sands</Typography>
                        <Typography className='stCardSummery'>Featuring wild windswept dunes and sun-scorched earth, we introduce 'Southern Sands', our new Summer Collection film. Shot in the south of Spain.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg4} />
                        <Typography className='stCardTitle'>Considered Guide to Paris</Typography>
                        <Typography className='stCardSummery'>Our Considered Guide series showcases our favourite cities through our eyes. We’ll be sharing places that echo the taste, spirit and passions of DeMellier: first up, Paris.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg5} />
                        <Typography className='stCardTitle'>Our SS24 Film</Typography>
                        <Typography className='stCardSummery'>Discover our sun-kissed campaign for Spring Summer 2024. Captured on the South of France featuring Giedre Dukauskaite, ‘Côte d'Azur’ is an ode to relaxed elegance.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>
                <Box className="stImgBox1">
                    <Box className="stimgInBox">
                        <img src={stImg6} />
                        <Typography className='stCardTitle'>A Night in Paris</Typography>
                        <Typography className='stCardSummery'>Step inside The Paris Collection launch dinner, co-hosted by DeMellier and Dame Menna Rawlings, the first female British Ambassador to France.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox">
                        <img src={stImg7} />
                        <Typography className='stCardTitle'>Women's Hour: Alison Wallace</Typography>
                        <Typography className='stCardSummery'>We spoke to  Alison Wallace, senior leader in the not-for-profit sector and CEO of SOS Children's Villages UK.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>
                <Box className="stImgBox2">
                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg8} />
                        <Typography className='stCardTitle'>ICONS 23: The Small Vancouver x Anny Fan</Typography>
                        <Typography className='stCardSummery'>Anny shared with us her love of textiles, and her highlights of redesigning The Small Vancouver.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg9} />
                        <Typography className='stCardTitle'>ICONS 23: The London x Hannah Strafford-Taylor</Typography>
                        <Typography className='stCardSummery'>Hannah shared with us her passion for accessories, and her thoughts on redesigning The London. </Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg10} />
                        <Typography className='stCardTitle'>ICONS 23: The Tokyo x Taylor LaShae</Typography>
                        <Typography className='stCardSummery'>We chatted with Taylor to discover what she enjoyed most about the collaboration.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>
                <Box className="stImgBox1">
                    <Box className="stimgInBox">
                        <img src={stImg11} />
                        <Typography className='stCardTitle'>ICONS 23: The Vancouver x Kelsey Merritt</Typography>
                        <Typography className='stCardSummery'>We caught up with Kelsey to find out more about her inspirations and why she redesigned The Vancouver.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox">
                        <img src={stImg12} />
                        <Typography className='stCardTitle'>ICONS 23: The New York x Josefine H.J</Typography>
                        <Typography className='stCardSummery'>Josefine shared with us her enthusiasm for the design process and what she loved most about recreating The New York. </Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>
                <Box className="stImgBox2">
                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg13} />
                        <Typography className='stCardTitle'>Our AW23 Film</Typography>
                        <Typography className='stCardSummery'>Inspired by the vibrancy of city life, embodied by the one and only New York</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg14} />
                        <Typography className='stCardTitle'>New York Edition</Typography>
                        <Typography className='stCardSummery'>To celebrate the New York family, we welcomed friends of the brand to a special evening amongst the iconic skyline of the city that never sleeps</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg15} />
                        <Typography className='stCardTitle'>Women's Hour: Chloé Reuter</Typography>
                        <Typography className='stCardSummery'>We caught up with Chloé Reuter, Founding Partner and Vice Chair of Gusto Collective.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>

                <Box className="stImgBox1">
                    <Box className="stimgInBox">
                        <img src={stImg16} />
                        <Typography className='stCardTitle'>Our PF23 Film- Summer Escapism</Typography>
                        <Typography className='stCardSummery'>Our PF23 collection was inspired by summer escapism and the possibilities of the open road.</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox">
                        <img src={stImg17} />
                        <Typography className='stCardTitle'>Celebrating Our Motherly Figures</Typography>
                        <Typography className='stCardSummery'>Members of the DeMellier team share their special memories</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>

                <Box className="stImgBox2">
                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg18} />
                        <Typography className='stCardTitle'>Our SS23 Film - Beautiful Simplicity</Typography>
                        <Typography className='stCardSummery'>A joyful collection, stand out pieces fit seamlessly alongside a selection of core styles. </Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg19} />
                        <Typography className='stCardTitle'>International Women's Day: Fanny Moizant</Typography>
                        <Typography className='stCardSummery'> We talk to Vestiaire Collective’s President & Co-Founder</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg20} />
                        <Typography className='stCardTitle'>Our Pre-SS23 Film- Step into Spring</Typography>
                        <Typography className='stCardSummery'>Our Pre-Spring Summer 23 collection was inspired by the soft transition from winter to spring. </Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>

                <Box className="stImgBox1">
                    <Box className="stimgInBox">
                        <img src={stImg21} />
                        <Typography className='stCardTitle'>ICONS: The Verona x Jen Ceballos</Typography>
                        <Typography className='stCardSummery'>Jen shared with us her interest in fashion and her thoughts on the exclusive Verona bag</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>

                    <Box className="stimgInBox">
                        <img src={stImg22} />
                        <Typography className='stCardTitle'>ICONS: The Vancouver x Felicia Akerstrom</Typography>
                        <Typography className='stCardSummery'>Felicia shared with us her passion for handbags and the design process of the Vancouver bag</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                </Box>

                <Box className="stImgBox2 stImgBox2Last ">
                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg23} />
                        <Typography className='stCardTitle'>ICONS: The Sloane x Anouk Yve</Typography>
                        <Typography className='stCardSummery'>We chatted with Anouk to find out more about her fondness for knitwear. </Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                    <Box className="stimgInBox stimgInBoxV2">
                        <img src={stImg24} />
                        <Typography className='stCardTitle'>ICONS: The Nano Montreal x Lorna Humphrey</Typography>
                        <Typography className='stCardSummery'>Find out more about the designing process and what Lorna loves most about the limited edition Nano Montreal</Typography>
                        <Typography className='readMoreText'>Read more</Typography>
                    </Box>
                    <Box className="stimgInBox stimgInBoxV2 lastBtnBox">
                        <Box className="shopSLabelTextBox" data-aos="fade-up" >
                        <span>Experience the <strong>brand</strong></span>
                        <span className='ssltbLine'></span>
                        </Box>
                    </Box>
                </Box>*/}
                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
