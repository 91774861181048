import React, { useEffect, useState } from 'react';
import "./style.css";
import config from "../../config";
import axios from "axios";
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from "../../lib/constants";

import {
    Box,
    TextField,
    Button,
    MenuItem,
    Typography,
    Modal,
    Checkbox,
    Select,
    IconButton,
    Hidden
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import closeIcon from "../../Assets/Images/delete.png";

export default function AddAddressPop({ open, handleClose, editFormData, userId }) {
    const userSession = localStorage.getItem("User");
    const BACKEND_URL = config.BACKEND_URL;
    const [notify, setNotify] = useState([]);
    const [isOpen, setIsOpen] = useState(open);
    const [countries, setCountry] = useState([]);
    const [states, setState] = useState([]);
    const [cities, setCities] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    console.log("Login User Id",userId);

    const [formData, setFormData] = useState({
        edit : false,
        address_id : "",
        user_id : "",
        first_name: "",
        last_name: "",
        phone : "",
        email : "",
        address: "",
        country_id: "",
        state_id : "",
        city_id: "",
        postal_code: "",
        isDefault : "No"
    });

    useEffect(() => {
        if (userId !== undefined) {
            setFormData(prevFormData => ({
                ...prevFormData,
                user_id: userId, 
            }));
        }
    }, [userId]);
    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        if(e.target.name =="country_id" ){
            getState(e.target.value)
        }
        if(e.target.name =="state_id" ){
            getCities(e.target.value)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex = /^[0-9]+$/;
        if(
            formData.first_name != "" &&
            formData.last_name != "" &&
            formData.phone != "" &&
            formData.email != "" &&
            formData.address != "" &&
            formData.country_id != "" &&
            formData.state_id != "" &&
            formData.city_id != "" &&
            formData.postal_code != "" &&
            formData.isDefault != ""
        ){
            if (!emailRegex.test(formData.email)) {
                toast.error("Invalid email address", TOAST_CONFIG);
                return;
            }
    
            if (!phoneRegex.test(formData.phone)) {
                toast.error("Phone number must contain only digits", TOAST_CONFIG);
                return;
            }

            setIsProcessing(true);
            // console.log("Form Data",formData);
            if(formData.edit == false){
                axios
                .post(`${BACKEND_URL}/api/V1/profile/user-address/create`, formData)
                .then((response) => {
                    if (response.data.status) {
                        toast.success(response.data.message, TOAST_CONFIG);
                        handleClose();
                        resetAllField();
                        setIsProcessing(false);
                    } else {
                        setIsProcessing(false);
                        // const notifys = () => toast.error(response.data.message);
                        // setNotify(notifys);
                        toast.error(response.data.message, TOAST_CONFIG);
                    }
                })
                .catch((error) => {
                    // const notifys = () => toast.error("Error fetching data");
                    // setNotify(notifys);
                    toast.error("Error fetching data", TOAST_CONFIG);
                    setIsProcessing(false);
                    console.error("Error fetching data:", error);
                    // setErrorMessage("Unable to register");
                });
            }
            if(formData.edit == true){
                axios
                .post(`${BACKEND_URL}/api/V1/profile/user-address/update`, formData)
                .then((response) => {
                    if (response.data.status) {
                        // const notifys = () => toast.success(response.data.message);
                        // setNotify(notifys);
                        toast.success(response.data.message, TOAST_CONFIG);
                        handleClose();
                        resetAllField()
                        setIsProcessing(false);
                    } else {
                        // const notifys = () => toast.error(response.data.message);
                        // setNotify(notifys);
                        toast.error(response.data.message, TOAST_CONFIG);
                        setIsProcessing(false);
                    }
                })
                .catch((error) => {
                    // const notifys = () => toast.error("Error fetchin data");
                    // setNotify(notifys);
                    toast.error("Error fetchin data", TOAST_CONFIG);
                    console.error("Error fetching data:", error);
                    setIsProcessing(false);
                    // setErrorMessage("Unable to register");
                });
            }
        }else{
            // console.log("Form Data",formData);
            // const notifys = () => toast.error("Address all field are required");
            // setNotify(notifys);
            toast.error("Address all field are required", TOAST_CONFIG);
        }

        
    };

    const resetAllField = () => {
        formData.first_name = "";
        formData.last_name = "";
        formData.phone = "";
        formData.email = "";
        formData.address = "";
        formData.country_id = "";
        formData.state_id = "";
        formData.city_id = "";
        formData.postal_code = "";
        formData.isDefault = "No";
    }
    
    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/get-countries`)
        .then((response) => {
            setCountry(response.data.result.countries);
        })
        .catch((error) => {
            // const notifys = () => toast.error("Error fetchin layout data");
            //     setNotify(notifys);
                toast.error("Error fetching layout data", TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
        
        if (open && editFormData?.edit) {
            setFormData((prevData) => ({
                ...prevData,
                edit: true,
                address_id : editFormData?.address_id || "",
                first_name: editFormData?.first_name || "",
                last_name: editFormData?.last_name || "",
                phone: editFormData?.phone || "",
                email: editFormData?.email || "",
                address: editFormData?.address || "",
                country_id: editFormData?.country_id || "",
                state_id: editFormData?.state_id || "",
                city_id: editFormData?.city_id || "",
                postal_code: editFormData?.postal_code || "",
                isDefault: editFormData?.isDefault || "No",
            }));
    
            // Fetch the state and city based on the country and state
            if (editFormData.country_id) {
                axios
                    .get(`${BACKEND_URL}/api/V1/get-state?country_id=${editFormData.country_id}`)
                    .then((response) => {
                        if(response.data.status){
                            setState(response.data.result.states);
                        }else{
                            // const notifys = () => toast.error(response.data.message);
                            // setNotify(notifys);
                            toast.error(response.data.message, TOAST_CONFIG);
                        }
                    })
                    .catch((error) => {
                        // const notifys = () => toast.error("Error fetching data");
                        // setNotify(notifys);
                        toast.error("Error fetching data", TOAST_CONFIG);
                        console.error("Error fetching layout data:", error);
                    });
            }
    
            if (editFormData.state_id) {
                axios
                    .get(`${BACKEND_URL}/api/V1/get-cities?state_id=${editFormData.state_id}`)
                    .then((response) => {
                        if(response.data.status) {
                            setCities(response.data.result.cities);
                        }else{
                            // const notifys = () => toast.error(response.data.message);
                            // setNotify(notifys);
                            toast.error(response.data.message, TOAST_CONFIG);
                        }
                    })
                    .catch((error) => {
                        // const notifys = () => toast.error("Error fetching data");
                        // setNotify(notifys);
                        toast.error("Error fetching data", TOAST_CONFIG);
                        console.error("Error fetching layout data:", error);
                    });
            }
        }
    }, [open, editFormData]); // Adding `editFormData` to dependency array
    
    const getState = (id) => {
        axios
        .get(`${BACKEND_URL}/api/V1/get-state?country_id=${id}`)
        .then((response) => {
            if(response.data.status){
                setState(response.data.result.states);
            }else{
                // const notifys = () => toast.error(response.data.message);
                // setNotify(notifys);
                toast.error(response.data.message, TOAST_CONFIG);
            }
        })
        .catch((error) => {
            // const notifys = () => toast.error("Error fetching data");
            // setNotify(notifys);
            toast.error("Error fetching data", TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
    }
    const getCities = (id) => {
        axios
        .get(`${BACKEND_URL}/api/V1/get-cities?state_id=${id}`)
        .then((response) => {
            if(response.data.status){
                setCities(response.data.result.cities);
            }else{
                // const notifys = () => toast.error(response.data.message);
                // setNotify(notifys);
                toast.error(response.data.message, TOAST_CONFIG);
            }
        })
        .catch((error) => {
            // const notifys = () => toast.error("Error fetching data");
            // setNotify(notifys);
            toast.error("Error fetching data", TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
    }

    return (
        <Modal open={open} onClose={handleClose} className="address-modal">
            
            <Box className="address-container">
                <Box className="popup-header">
                    <Typography className="profileHeaderM2 bVariant">
                        Add a New Address
                    </Typography>
                    <Box onClick={handleClose}>
                        <img className="closeIcon" src={closeIcon} alt="close" />
                    </Box>
                </Box>
                <Box component="form" className="addBookForm" onSubmit={handleSubmit}>
                    <Hidden name="address_id" value={formData?.address_id || ""} hidden />
                    <Box className="form-group">
                        <TextField label="First name" name="first_name" value={formData?.first_name || ""} fullWidth onChange={handleChange} />
                    </Box>
                    <Box className="form-group">
                        <TextField label="Last name" name="last_name" value={formData?.last_name || ""} fullWidth onChange={handleChange} />
                    </Box>
                    <Box className="form-group">
                        <TextField label="Phone" name="phone" value={formData?.phone || ""} fullWidth onChange={handleChange} />
                    </Box>
                    <Box className="form-group">
                        <TextField label="Email" name="email" value={formData.email || ""} fullWidth onChange={handleChange} />
                    </Box>
                    <Box className="form-group">
                        <TextField label="Address line one" name="address" value={formData.address || ""} fullWidth onChange={handleChange} />
                    </Box>
                    <Box className="form-group" style={{ color : "#000 !important" }}>
                        <TextField label="Country/region" name="country_id" value={formData.country_id} select fullWidth onChange={handleChange} >
                            {countries.map((countrie, index) => (
                                <MenuItem color="primary" value={countrie.id}>{countrie.name}</MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box className="form-group" style={{ color : "#000" }}>
                        <TextField label="State" name="state_id" value={formData.state_id} select fullWidth onChange={handleChange} >
                            {states.map((state, index) => (
                                <MenuItem color="primary" value={state.id}>{state.name}</MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box className="form-group" style={{ color : "#000" }}>
                        <TextField label="City" name="city_id" value={formData.city_id} select fullWidth onChange={handleChange} >
                            {cities.map((citie, index) => (
                                <MenuItem color="primary" value={citie.id}>{citie.name}</MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box className="form-group">
                        <TextField label="Post Code" name="postal_code" value={formData.postal_code || ""} fullWidth onChange={handleChange} />
                    </Box>
                    <Box className="form-group">
                        <TextField label="Is Default" name="isDefault" value={formData.isDefault} select fullWidth onChange={handleChange} >
                            <MenuItem  color="primary" value='Yes'>Yes</MenuItem>
                            <MenuItem  color="primary" value='No'>No</MenuItem>
                        </TextField>
                    </Box>
                    <Box className="form-group submit-button">
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                        {isProcessing ? "Processing..." : "Save Address"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
