import React, { useEffect, useState } from 'react';
import "./style.css";
import config from "../../config";
import axios from "axios";
import { toast,ToastContainer } from 'react-toastify';
import { TOAST_CONFIG } from "../../lib/constants";
import 'react-toastify/dist/ReactToastify.css';

import noResults from "../../Assets/Images/no-results.png";
import addNew from "../../Assets/Images/plus.png";

import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

import { Box, Button, Typography } from "@mui/material";
import AddAddressPop from "../AddAddressPop";

export default function AddressBook({ profileMenuTab, setProfileMenuTab }) {
    const BACKEND_URL = config.BACKEND_URL;
    const userSession = localStorage.getItem("User");
    const [isOpen, setIsOpen] = useState(false);
    const [userAddress, setUserAddress] = useState([]);
    const [userId, setUserId] = useState();

    useEffect(() => {
        if (userSession) {
            const User = JSON.parse(userSession);
            setUserId(User.id);
        }    
        
    }, [userSession]);

    const [editFormData, setEditFormData] = useState({
        edit : false,
        address_id : "",
        user_id : userId,
        first_name: "",
        last_name: "",
        phone : "",
        email : "",
        address: "",
        country_id: "",
        state_id : "",
        city_id: "",
        postal_code: "",
        isDefault : "No"
    });
    const openPopup = () => {
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (userSession) {
            const User = JSON.parse(userSession);
        getAddress(User.id)
    }
    }, [userSession]);
    const getAddress = (userValue) => {
        const postdata = { user_id : userValue }
        axios
        .post(`${BACKEND_URL}/api/V1/profile/get-address`,postdata)
        .then((response) => {
            setUserAddress(response.data.result.address);
            console.log("address Data @@ =",userAddress);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
    const editopenPopup = (address) => {
        setIsOpen(true);
        editFormData.edit = true;
        editFormData.address_id = address.id;
        editFormData.user_id = userId;
        editFormData.first_name = address.first_name;
        editFormData.last_name = address.last_name;
        editFormData.phone = address.phone;
        editFormData.email = address.email;
        editFormData.address= address.address;
        editFormData.country_id= address.country_id;
        editFormData.state_id = address.state_id;
        editFormData.city_id= address.city_id;
        editFormData.postal_code= address.postal_code;
        editFormData.isDefault = address.isDefault;
    }
    const addressDeleteBtn = (id) => {
        axios
        .get(`${BACKEND_URL}/api/V1/profile/user-address/delete/${id}`)
        .then((response) => {
            if(response.data.status){
                getAddress(userId);
                alert(response.data.message);
            }else{
                alert(response.data.message);
            }            
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
    return (
        <Box
            className="accDetailContainer emptyContainer"
            sx={{ display: profileMenuTab == 2 ? "flex" : "none" }}
        >
            <ToastContainer />
            <AddAddressPop open={isOpen} handleClose={closePopup} editFormData={editFormData} userId={userId} />
            <Box className="addressPageContainer">
                <Box className="newAddressBox">
                    <Box className="addNewBox" onClick={openPopup}>
                        <img className="addNewIcon" src={addNew} alt="" />
                        <Typography className="profileHeaderM bVariant">
                            Add New Address
                        </Typography>
                    </Box>
                </Box>                    
                {userAddress && Array.isArray(userAddress) && userAddress.map((address, index) => (
                    <Box className="addressContainer" key={index}>
                        <Box>
                            <Box className="addInfo">
                                <Typography className="addressText">
                                    {address.full_name}
                                </Typography>
                                <Typography className="addressText">
                                    {address.address}
                                </Typography>
                                <Typography className="addressText">
                                    {address.city_name}, {address.countrie_name}
                                </Typography>
                                <Typography className="addressText">
                                    {address.postal_code}
                                </Typography>
                                <Typography className="addressText">
                                    {address.Country}
                                </Typography>
                            </Box>
                            <Box className="btnBox">
                                <Box className="editText" onClick={() => editopenPopup(address)}>
                                    Edit
                                </Box>
                                <Box className="editText" onClick={() => addressDeleteBtn(address.id)}>
                                    Delete
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
