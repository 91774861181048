import React, { useState } from 'react';
import "./style.css";

//components
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import VideoWithPlayButton from "../../Components/VideoPlayer"
import CookiePop from "../../Components/CookiePop"

//images
import rafaImg1 from "../../Assets/Images/rafaImg1.jpeg"
import slideImg1 from "../../Assets/Images/rafaSliderImg1.jpeg"
import slideImg2 from "../../Assets/Images/rafaSliderImg2.jpeg"
import slideImg3 from "../../Assets/Images/rafaSliderImg3.jpeg"
import caviBolImg from '../../Assets/Images/caviIBolImg.jpg'
import redFlowar from "../../Assets/Images/redFlowar.png"
import chefSection from "../../Assets/Images/chefSection.jpg"

export default function RefaCagali({ cookiePop, setCookiePop }) {
  const [check, setCheck] = useState(false);


  return (
    <>
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />
      <Box className="rafaPage">
        <Box className="rafahomeTextBox">
          <Typography>N25 AMBASSADOR, RAFA CAGALI</Typography>
        </Box>

        <Box className="rafaImgBox1">
          <img src={rafaImg1} />
        </Box>

        <Box className="RafatextBox">
          <Typography className='rafaTBHText'>"N25 aligns with my values because of their dedication to selecting the best quality and their individuality to bring a unique experience to the table"</Typography>
          <Typography className='rafaTBNText'>Rafa Cagali of Two-Michelin-Starred restaurant Da Terra in London explains what makes N25 unique.</Typography>
        </Box>
        <VideoWithPlayButton />

        <Box className="RafatextBox RafatextBox2">
          <Typography className='rafaTBHText'>"As a chef I am constantly looking for the best quality produce to work with"</Typography>
          <Typography className='rafaTBNText'>"The uniqueness of this caviar is the older sturgeons and the extended ageing process which brings the nuttiness to the palate. What stands out is the creaminess while still being clean in umami flavour. And that, is what I am looking for."</Typography>
        </Box>


        <Box className="rafaImgSlider">
          <Box className="rafaSliderImgBox">
            <img src={slideImg1} />
          </Box>
          <Box className="rafaSliderImgBox">
            <img src={slideImg2} />
          </Box>
          <Box className="rafaSliderImgBox">
            <img src={slideImg3} />
          </Box>
        </Box>

        <Box className="caviarBolBox">
          <Box className="cavImgBox">
            <img src={caviBolImg} />
          </Box>

          <Box className="cavTextBox">
            <Typography className='caviText1'>The Choice of Caviar</Typography>
            <Typography className='caviText2'>The perfect choice of caviar for this dish is the N25 Kaluga Hybrid caviar due to the subtle sweetness and refined elegant flavour profile - pure and clean.</Typography>

            <Box className="shopSLabelTextBox" data-aos="fade-up">
              <span>Our <strong>Caviar</strong></span>
              <span className='ssltbLine'></span>
            </Box>
          </Box>
        </Box>


        <Box className="cagaliBox">
          <Box className="cagaliTextBox">
            <Typography className='cagalText1'>REFAEL CAGALI</Typography>
            <Typography className='cagalText2'>Da Terra – London, United Kingdom</Typography>
            <Box className="redFBox">
              <img src={redFlowar} />
              <img src={redFlowar} />
            </Box>
          </Box>

          <Box className="cagaliImgBox">
            <img src={chefSection} />
          </Box>


        </Box>


        {/* mail section */}
        <Box className="mailSection">
          <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
          <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

          <Box className="mailInputBox">
            <input type="email" name="email" placeholder='Your email*' />
            <Box className="submitBtn">
              {whiteArrow}
            </Box>
          </Box>

          <Box className="MailCheckBox">
            <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
            <Typography>Please click here to accept our</Typography>
            <Box className="termsbox">
              <Typography>Terms and Conditions</Typography>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </>
  )
}
