import React, { useEffect, useState } from 'react';
import "./style.css";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import { toast } from 'react-toastify';
import EmailCommunity from '../../Components/EmailCommunity';
import { useNavigate } from "react-router-dom";
import { TOAST_CONFIG } from "../../lib/constants";

export default function ContactPage({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const navigate = useNavigate();
    // const [check, setCheck] = useState(false);
    const [Fcheck, setFCheck] = useState(false);
    const [Scheck, setSCheck] = useState(false);
    const [firstSection, setFirstSection] = useState([]);
    const [contactLoading, setContactLoading] = useState(false);

    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/contact-us/content`)
        .then((response) => {
            setFirstSection(response.data.result.content);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    const initialContactState = {
        name: "",
        phone:"",
        email: "",
        request:"",
        message: "",
        news_letter: false,
        term_conditions: false,
      };
    
      const [contactData, setContactData] = useState(initialContactState);

      const validationObj = {
        hasErr: false,
        name: {
          error: false,
          msg: "",
        },
        email: {
            error: false,
            msg: "",
          },
          phone: {
            error: false,
            msg: "",
          },
          request: {
            error: false,
            msg: "",
          },
          message: {
            error: false,
            msg: "",
          },
          news_letter: {
            error: false,
            msg: "",
          },
          term_conditions: {
            error: false,
            msg: "",
          },
      };
    
      const [validationErrors, setValidationErrors] = useState({
        ...validationObj,
      });

      const handleValidation = (formInput) => {
        formInput = formInput || contactData;
        let validationerr = { ...validationObj };
    
        if (formInput.name == "") {
          validationerr.hasErr = true;
          validationerr.name = {
            error: true,
            msg: "Name is required",
          };
        }
          if (formInput.email == "") {
            validationerr.hasErr = true;
            validationerr.email = {
              error: true,
              msg: "Email is required",
            };
          }
          if (formInput.phone == "") {
            validationerr.hasErr = true;
            validationerr.phone = {
              error: true,
              msg: "Phone is required",
            };
          }
          if (formInput.request == "") {
            validationerr.hasErr = true;
            validationerr.request = {
              error: true,
              msg: "Request is required",
            };
          }
          if (formInput.message == "") {
            validationerr.hasErr = true;
            validationerr.message = {
              error: true,
              msg: "Message is required",
            };
          }

          if (formInput.news_letter == "") {
            validationerr.hasErr = true;
            validationerr.news_letter = {
              error: true,
              msg: "News Letter is required",
            };
          }
          if (formInput.term_conditions == "") {
            validationerr.hasErr = true;
            validationerr.term_conditions = {
              error: true,
              msg: "Term conditions is required",
            };
          }
    
        setValidationErrors(validationerr);
        if (validationerr.hasErr) {
          return false;
        }
        return true;
      };
    
      const setFormData = (e) => {
        let newState = {
          ...contactData,
          [e.target.name]: e.target.value,
        };
        setContactData(newState);
        if (validationErrors.hasErr) {
          handleValidation(newState);
        }
      };

      const handleTermSetting = (e) => {
        setFCheck(!Fcheck);
        setContactData({
            ...contactData,
            news_letter: !Fcheck,
          });
      }

      const handleTermSetting2 = (e) => {
        setSCheck(!Scheck);
        setContactData({
            ...contactData,
            term_conditions: !Scheck,
          });
      }

      let saveContact = async () => {
        if (!handleValidation()) {
          toast.error("Correct form data!",TOAST_CONFIG);
          return false;
        }
        console.log("Final Submit @@ = ",contactData);
        if (
            contactData.name !== "" &&
            contactData.email !== "" &&
            contactData.phone !== "" &&
            contactData.request !== "" &&
            contactData.message 
        ) {
          setContactLoading(true);
        axios
          .post(`${BACKEND_URL}/api/V1/contact`, contactData)
          .then((response) => {
            console.log("response @ = ",response.data);
              if (response.data.status) {
                  toast.success(response.data.result.message, TOAST_CONFIG);
                  setContactLoading(false);
                  setContactData(initialContactState);
              } else {
                  toast.error(response.data.errors, TOAST_CONFIG);
              }
          })
          .catch((error) => {
              toast.error("Error fetching data", TOAST_CONFIG);
              console.error("Error fetching data:", error);
          });
        }
      };

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
                <Box className="contactInputSection">
                    {firstSection.map((item) => {
                        return(
                            <Box className="contactInfo">
                                <Typography className='textInfo1'>{item.title}</Typography>
                                <Typography className='contactLabel'>ADDRESS</Typography>
                                <Typography className='contactValue' width={"70%"}>{item.address}</Typography>
                                <Typography className='contactLabel'>PHONE</Typography>
                                <Typography className='contactValue'>{item.first_phone_number}</Typography>
                                <Typography className='contactLabel'>PHONE</Typography>
                                <Typography className='contactValue'>{item.second_phone_number}</Typography>
                                <Typography className='contactLabel'>EMAIL</Typography>
                                <Typography className='contactValue pointer'>{item.email}</Typography>
                            </Box>
                        )
                    })}

                    <Box className="contactInputBox">
                        <Box className="contactFInBox">
                            <Box className="contactAddressBox">
                                <Typography className="inputLabel">YOUR FULL NAME</Typography>
                                <input type="text" className='addressinput' placeholder='Your Full Name' name="name"
                      value={contactData.name ? contactData.name : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.name.msg}</span>

                                <Typography className="inputLabel">YOUR PHONE NUMBER</Typography>
                                <input type="text" className='addressinput' placeholder='Your phone number' name="phone"
                      value={contactData.phone ? contactData.phone : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.phone.msg}</span>
                            </Box>

                            <Box className="contactAddressBox">
                                <Typography className="inputLabel">EMAIL</Typography>
                                <input type="text" className='addressinput' placeholder='Your email*' name="email"
                      value={contactData.email ? contactData.email : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.email.msg}</span>

                                <Typography className="inputLabel">WHAT'S YOUR REQUEST?</Typography>
                                <input type="text" className='addressinput' placeholder='Your Request ' name="request"
                      value={contactData.request ? contactData.request : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.request.request}</span>
                            </Box>
                        </Box>

                        <Box className="inputMessageBox">
                            <Typography className="inputLabel">YOUR MESSAGE</Typography>
                            <textarea className='messageInput' placeholder='Your Message*' name="message"
                      value={contactData.message ? contactData.message : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.message.request}</span>

                            <Box className="MailCheckBox MailCheckBoxL">
                                <Box className={Fcheck ? "check checkActive" : "check"} onClick={(e) => handleTermSetting(e)}></Box>
                                <Typography>Check here to always receive our Newsletter with the latest products and informations about exclusive events.</Typography>
                            </Box>
                            <span className="validationerr">{validationErrors.news_letter.msg}</span>
                            <Box className="MailCheckBox ">
                                <Box className={Scheck ? "check checkActive" : "check"} onClick={(e) => handleTermSetting2(e)}></Box>
                                <Typography>Please check here to accept our</Typography>
                                <Box className="termsbox" onClick={() => navigate("/terms-and-conditions")}>
                                <Typography>Terms and Conditions</Typography>
                                <Box></Box>
                                </Box>
                            </Box>
                            <span className="validationerr">{validationErrors.term_conditions.msg}</span>
                            <span className='discoverBtn' onClick={() =>saveContact()} disabled={contactLoading}>
                                <Typography>{contactLoading ? "Save..." : "Save"}</Typography>
                                <span className='dbsLine dbTops'></span>
                                <span className='dbsLine dbRighs'></span>
                                <span className='dbsLine dbBottoms'></span>
                                <span className='dbsLine dbLefts'></span>
                            </span>
                        </Box>
                    </Box>
                </Box>

                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
