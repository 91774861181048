import React, { useEffect, useState } from 'react';
import "./style.css";
import { useNavigate } from "react-router-dom";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import { UnderLBtn } from "../../Components/AppBtn"
import VideoWithPlayButton from "../../Components/VideoPlayer"
import EmailCommunity from '../../Components/EmailCommunity';

//images
import hybridImg from "../../Assets/Images/RB-Vogue-mar.png";
import dropIcon from "../../Assets/Images/dropIcon.png"


export default function N25({ cookiePop, setCookiePop }) {
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [menu, setMene] = useState(false);

  const closeMenu = (e) => {
    if (e.target.id === "bottomMenu") {
      setMene(false)
    }
  }

  useEffect(() => {
    if (menu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [menu]);

  return (
    <>
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />
      <Box className="n25Page">
        <Box id="bottomMenu" sx={{ display: menu ? "flex" : "none" }} className="bottomMenu" onClick={closeMenu}>
          <Box className="bmInnerBox">
            {UnderLBtn({ btnText: "Our Story", active: true })}
            {UnderLBtn({ btnText: "Our Spaces" })}
            {UnderLBtn({ btnText: "Loyalty Service" })}
            {UnderLBtn({ btnText: "Sustainability" })}
            {UnderLBtn({ btnText: "Careers" })}
          </Box>
        </Box>


        <Box className="nBox1">
          <Box className="nb1InnerBox nb1HeaderRTextBox">
            <Typography className='nb1HeaderRText'>The House</Typography>
            <img src={dropIcon} className='n25DropIcon' style={{ rotate: menu ? "90deg" : "0deg" }} onClick={() => setMene(!menu)} />
          </Box>
          <Box className="nb1InnerBox nb1BtnBTab">
            {/* onClick added */}
            {UnderLBtn({ btnText: "Our Story", active: true })}
            {UnderLBtn({ btnText: "Our Spaces" })}
            {UnderLBtn({ btnText: "Loyalty Service" })}
            {UnderLBtn({ btnText: "Sustainability" })}
            {UnderLBtn({ btnText: "Careers" })}
          </Box>
          <Box className="nb1InnerBox nb1PText">
            <Typography>TOTEME is a fashion house rooted in Swedish sensibilities, modern aspirations and the notion of style.</Typography>
            <Box className="gapBox"></Box>
            <Typography>With an appreciation for women’s many roles and how pieces are worn in practice, we create emblematic designs with an emphasis on materiality and shape. Archetype pieces are examined through a female lens and realized with sumptuous fabrics, rich textures and graphic silhouettes. Representing a direct and decisive way of dressing, the collections are contextualized in curated edits, visuals and spaces.</Typography>
          </Box>
        </Box>


        <Box className="nBox2">
          <Box className="nb2ImgBox">
            <img src={hybridImg} />
            <Typography>Reserve Hybrid Caviar</Typography>
          </Box>
        </Box>

        <Box className="nBox3">
          <Typography>TOTEME was founded in 2014 by Elin Kling and Karl Lindman. From the studio in Stockholm, we create collections spanning ready-to-wear, shoes, bags, accessories and jewelry.</Typography>
        </Box>

        <VideoWithPlayButton />


        {/* mail section */}
        {/* <Box className="mailSection">
          <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
          <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

          <Box className="mailInputBox">
            <input type="email" name="email" placeholder='Your email*' />
            <Box className="submitBtn">
              {whiteArrow}
            </Box>
          </Box>

          <Box className="MailCheckBox">
            <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
            <Typography>Please click here to accept our</Typography>
            <Box className="termsbox">
              <Typography>Terms and Conditions</Typography>
              <Box></Box>
            </Box>
          </Box>
        </Box> */}
        <EmailCommunity />
      </Box>
      <Footer />
    </>
  )
}
