import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import { Box, Typography } from '@mui/material';


const VideoWithPlayButton = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [delayedPos, setDelayedPos] = useState({ x: 0, y: 0 });
  const videoRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseMove = (e) => {
    const rect = videoRef.current.getBoundingClientRect();
    setMousePos({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a timeout to update the delayed position
    timeoutRef.current = setTimeout(() => {
      setDelayedPos({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }, 1); // Adjust the delay here (in milliseconds)
  };

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div
      className="videoContainer"
      onMouseMove={handleMouseMove}
      style={{ cursor: 'pointer' }}
    >
      <video
        ref={videoRef}
        className='foodVideoSection'
        autoPlay
        muted
        loop
      >
        <source src='https://cms.n25caviar.com/wp-content/uploads/2023/08/N25-Da-Terra-feature-720.mp4' />
      </video>
      <Box className="vbuttonBox">
        {!isPlaying && (
          <button
            className="playButton"
            style={{ top: delayedPos.y, left: delayedPos.x }}
            onClick={handlePlayPause}
          >
            ▶
          </button>
        )}
      </Box>


    </div>
  );
};

export default VideoWithPlayButton;
