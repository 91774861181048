import React, { useEffect, useState } from 'react';

//components
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import CookiePop from "../../Components/CookiePop"
import config from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import EmailCommunity from '../../Components/EmailCommunity';

export default function PeoplePage({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false)
    const [firstSection, setFirstSection] = useState([]);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [peoples, setPeoples] = useState([]);
    const [slug] = useState("home");
    
    useEffect(() => {
        const BACKEND_URL = config.BACKEND_URL;
        axios
          .get(`${BACKEND_URL}/api/V1/peoplePage`)
          .then((response) => {
            
            const people = response.data.result.peoples;
           
            setPeoples(people);
            console.log("people data @@ =",response.data.result.peoples);
            
          })
          .catch((error) => {
            console.error("Error fetching layout data:", error);
          });
      }, [slug]);


    return (
        <>
            <NavBar />
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <Box className="productPage">
                
                    
                        <Box className='productHeaderBox'>
                            <Typography className='ambHeader' data-aos="fade-up">Friends of the Brand</Typography>
                            <Typography className='productPageSuhHeader' data-aos="fade-up">We are proud to offer the finest caviar in the world, and even prouder to be accompanied on this journey by many chefs internationally who share this philosophy and appreciate the unique quality of the product and the close collaboration with the N25 Caviar team. As our caviar is not only a product that is used in the star gastronomy, but is also very popular in private use we are  proud to have built up great relationships and friendships over the years with interesting personalities who share our passion for caviar.</Typography>
                        </Box>
                   
        
                {/* <Box sx={{cursor:"pointer"}} className="productListBox">
                {peoples?.map((peopleitem) => {
                        return(
                                <Box className="productCard">
                                    <Box className="productImgBox">
                                        <img src={`${BACKEND_URL}${peopleitem.image}`} />
                                    </Box>
                                    <Typography>{peopleitem.name}</Typography>
                                    <Typography> {peopleitem.designation}</Typography>
                                </Box>
                        )
                    })}
                    
                </Box> */}
                    <Box className="ambCardBox">
                    {peoples?.map((peopleitem) => {
                        const { img } = peopleitem.image;
                        return (
                        <Box className="ambCard">
                            <img src={`${BACKEND_URL}${peopleitem.image}`} />
                            <Typography style={{ fontWeight: 'bold',textTransform: 'uppercase' }}>{peopleitem.name} </Typography>
                            <Typography> {peopleitem.address}Test</Typography>
                        </Box>
                        );
                    })}
                    </Box>

                {/* mail section */}
                
                <EmailCommunity />

            </Box>
            <Footer />
        </>
    )
}
