import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate, Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import config from "../../config";
//images
import { Clogo, UserIcon } from "../../Assets/Data";
import crossIcon from "../../Assets/Images/crossIcon.png";
import productImg from "../../Assets/Images/n25-oscietra-caviar-main-img.png";
//component
export default function NavBar({ activeNav, setActiveNav }) {
  const BACKEND_URL = config.BACKEND_URL;
  const navigate = useNavigate();
  const [navMenu, setNavMenu] = useState(false);
  const [cartPop, setCartPop] = useState(false);
  const [cartItem, setCartItem] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [totalCartItem, setTotalCartItem] = useState();
  const userSession = localStorage.getItem("User");
  const productList = [1, 2];

  const handleNavClose = (e) => {
    if (e.target.id === "grayBox") {
      setNavMenu(false);
      setCartPop(false);
    }
  };

  useEffect(() => {
    if (navMenu || cartPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    if (userSession) {
    const User = JSON.parse(userSession);
    const postData = {
      user_id: User.id,
    };
    getCartItem(postData);
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [navMenu, cartPop]);

  const getCartItem = (postData) => {
    console.log("check Post Data", postData);
    axios
      .post(`${BACKEND_URL}/api/V1/user-cart-data`, postData)
      .then((response) => {
        setCartItem(response.data.result.cartitem);
        setTotalAmount(response.data.result.total_amount);
        setTotalCartItem(response.data.result.cartitem.length);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
  };

  const removeCartItem = (cartid) => {
    if (userSession) {
    const User2 = JSON.parse(userSession);
    const postData = {
      cart_id: cartid,
    };

    axios
      .post(`${BACKEND_URL}/api/V1/remove-cart-item`, postData)
      .then((response) => {
        const postData = {
          user_id: User2.id,
        };
        getCartItem(postData);
        toast(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
    }
  };

  const handleUpCount = (cartid, qty) => {
    if (userSession) {
    const User6 = JSON.parse(userSession);
    const countVal = qty + 1;
    const postData = {
      cart_id: cartid,
      qty: countVal,
    };
    axios
      .post(`${BACKEND_URL}/api/V1/update-qty`, postData)
      .then((response) => {
        const userPostData = {
          user_id: User6.id,
        };
        getCartItem(userPostData);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
    }
  };

  const handleDownCount = (cartid, qty) => {
    qty = qty;
    if (qty === 1) {
      return qty;
    } else {
      qty = qty - 1;
    }

    const postData = {
      cart_id: cartid,
      qty: qty,
    };
    changeCartItemQty(postData);
  };

  const changeCartItemQty = (postData) => {
    if (userSession) {
      const User7 = JSON.parse(userSession);
    axios
      .post(`${BACKEND_URL}/api/V1/update-qty`, postData)
      .then((response) => {
        const userPostData = {
          user_id: User7.id,
        };
        getCartItem(userPostData);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
    }
  };

  const plusProductQty = (cartid, qty) => {
    console.log("cart ID : ", cartid);
    console.log("Qty : ", qty);
  };

  const redirectCheckout = () => {
    navigate("/payment");
  }
  return (
    <>
      <Box className="NavBar">
        <Box className="navInnerBox navInnerBox1">
          <Box className="navLineMenuBox" onClick={() => setNavMenu(!navMenu)}>
            <Box
              className={
                navMenu
                  ? "lineMenuBox lmb1 lineMenuBoxMActive"
                  : "lineMenuBox lmb2"
              }
            >
              <Box className="nml l1"></Box>
              <Box className="nml l2"></Box>
            </Box>
            <Box
              className={
                navMenu
                  ? "lineMenuBox lmb2 lineMenuBoxMActive"
                  : "lineMenuBox lmb2"
              }
            >
              <Box className="nml l1 l1v2"></Box>
              <Box className="nml l2 l1v2"></Box>
            </Box>
            <Box className={navMenu ? "NavSLine NavSLineActive" : "NavSLine"}>
              <Box className="l3"></Box>
            </Box>
          </Box>
          {Clogo}
        </Box>
        <Box className="navInnerBox">
          <Box
            className="TextFlipBox DshopText"
            onClick={() => navigate("/all-products")}
          >
            <Typography className="navItemTExt text1 shop-now">
              Shop Now
            </Typography>
            <Typography className="navItemTExt text2 shop-now">
              Shop Now
            </Typography>
          </Box>
          <Box className="cartWUBox">
            <Link to={"/profile"}>{UserIcon}</Link>
            <Typography onClick={() => setCartPop(true)} className="navCartText">
              Cart {totalCartItem}
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={handleNavClose}
          id="grayBox"
          className="grayBox"
          sx={{ display: navMenu || cartPop ? "block" : "none" }}
        ></Box>

        {/* Nav menu */}
        <Box className={navMenu ? "navMenu navMenuActive" : "navMenu"}>
          <Box className="navMenuMainSection">
            <Box className="fMidInBox nf2t">
              <Box className="TextFlipBox" onClick={() => navigate("/n25")}>
                <Typography className="navItemTExt text1">N25</Typography>
                <Typography className="navItemTExt text2">N25</Typography>
              </Box>
              <Box
                className="TextFlipBox"
                onClick={() => navigate("/our-Stories")}
              >
                <Typography className="navItemTExt text1">
                  Our Stories
                </Typography>
                <Typography className="navItemTExt text2">
                  Our Stories
                </Typography>
              </Box>
              <Box className="TextFlipBox" onClick={() => navigate("/people")}>
                <Typography className="navItemTExt text1">
                  Friends of the Brand
                </Typography>
                <Typography className="navItemTExt text2">
                  Friends of the Brand
                </Typography>
              </Box>
            </Box>
            <Box className="fMidInBox nvMTextB nf2t itText">
              <Box
                className="TextFlipBox"
                onClick={() => navigate("/our-caviar")}
              >
                <Typography className="navItemTExt text1">Caviar</Typography>
                <Typography className="navItemTExt text2">Caviar</Typography>
              </Box>
              <Box
                className="TextFlipBox"
                onClick={() => navigate("/our-accessories")}
              >
                <Typography className="navItemTExt text1">
                  Accessories / Delicacies
                </Typography>
                <Typography className="navItemTExt text2">
                  Accessories / Delicacies
                </Typography>
              </Box>
              <Box className="TextFlipBox">
                <Typography className="navItemTExt text1">Wines</Typography>
                <Typography className="navItemTExt text2">Wines</Typography>
              </Box>
            </Box>
            <Box className="fMidInBox NavLastMS">
              <Box className="TextFlipBox">
                <Typography className="navItemTExt text1">
                  The Caviar
                </Typography>
                <Typography className="navItemTExt text2">
                  The Caviar
                </Typography>
              </Box>

              <Box
                className="TextFlipBox"
                onClick={() => navigate("/n25-global")}
              >
                <Typography className="navItemTExt text1">
                  N25 Global
                </Typography>
                <Typography className="navItemTExt text2">
                  N25 Global
                </Typography>
              </Box>

              <Box className="TextFlipBox" onClick={() => navigate("/contact")}>
                <Typography className="navItemTExt text1">Contacts</Typography>
                <Typography className="navItemTExt text2">Contacts</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="fLastBox">
            <Box className="fLastInBox">
              <Typography>©2024 N25.</Typography>
            </Box>
            <Box className="fLastInBox">
              <Typography>All rights reserved.</Typography>
            </Box>
            <Box className="fLastInBox paddingNL">
              <Box className="TextFlipBox">
                <Typography className="navItemTExt text1">
                  Go to EU shop
                </Typography>
                <Typography className="navItemTExt text2">
                  Go to EU shop
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* -----------------cart--------------------- */}
        <Box className={cartPop ? "cartBox cartBoxActive" : "cartBox"}>
          <Box className="cartInnerBox">
            <Box className="cartNav">
              <Box className="cartCrossBtn" onClick={() => setCartPop(false)}>
                <img src={crossIcon} />
              </Box>
              <Typography>Cart {totalCartItem}</Typography>
            </Box>
            <Box className="cartListBox">
              {cartItem.map((item) => {
                return (
                  <Box className="productItem">
                    <Box className="cProductImg">
                      <img src={`${BACKEND_URL}${item.image}`} />
                    </Box>
                    <Box className="productItemTextBox">
                      <Typography className="cartProductTitle">
                        {item.prod_name}
                      </Typography>
                      <Box className="productBtnBox">
                        <Typography className="cartProductTitle">
                          £ {item.price}
                        </Typography>
                        <Box className="inputNumber">
                          <button
                            type="button"
                            onClick={() => handleDownCount(item.id, item.qty)}
                          >
                            -
                          </button>
                          <input type="text" name="" id="" value={item.qty} />
                          <button
                            type="button"
                            onClick={() => handleUpCount(item.id, item.qty)}
                          >
                            +
                          </button>
                        </Box>
                        <Box
                          className="cartCrossBtn"
                          onClick={() => removeCartItem(item.id)}
                        >
                          <img src={crossIcon} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{padding:"16px"}} className="cartTotalPBox">
              <Typography className="totalLavel">Cart total</Typography>
              <Typography className="totalValue">£ {totalAmount}</Typography>
            </Box>
            <Box sx={{padding:"16px"}} className="cartTotalPBox ntb">
              <Typography className="anountText">
                You have reached the amount for free shipping.
              </Typography>
            </Box>
            <Box className="cardFBtnBox">
              <span className="discoverBtn" onClick={() => setCartPop(false)}>
                <Typography>Continue Shopping</Typography>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
              <p className="orText">Or</p>
              <span className="discoverBtn cfBlackBtn" onClick={() => redirectCheckout()}>
                <Typography>Proceed to checkout</Typography>
                <span className="dbsLine dbTops"></span>
                <span className="dbsLine dbRighs"></span>
                <span className="dbsLine dbBottoms"></span>
                <span className="dbsLine dbLefts"></span>
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
