import React, { useEffect, useState } from "react";
import "./style.css";
import config from "../../config";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TOAST_CONFIG } from "../../lib/constants";

import { Box, TextField, Button, MenuItem, Typography, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
export default function EmailPreference({ profileMenuTab, setProfileMenuTab }) {
    const BACKEND_URL = config.BACKEND_URL;
    const userSession = localStorage.getItem("User");
    const [editingSections, setEditingSections] = useState([false, false, false]);
    const [notify, setNotify] = useState([]);
    const [user, setUserInformation] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const toggleEditComponent = (index) => {
        const newEditingSection = [...editingSections];
        newEditingSection[index] = !newEditingSection[index];
        setEditingSections(newEditingSection);
    };

    const [userId, setUserId] = useState();

    useEffect(() => {
        if (userSession) {
            const User = JSON.parse(userSession);
            setUserId(User.id);
        }    
        
    }, [userSession]);

    const [formData, setFormData] = useState({
        user_id : '',
        never_miss_new: false,
        boot_style_iq : false,
    });

    useEffect(() => {
        if (userId !== undefined) {
            setFormData(prevFormData => ({
                ...prevFormData,
                user_id: userId,
            }));
        }
    }, [userId]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked,
        });
    };
    useEffect(() => {
        // getUser()
        if (userSession) {
        const User = JSON.parse(userSession);
        
        const user_id = User.id;
        axios
        .get(`${BACKEND_URL}/api/V1/get-profile-information?user_id=${user_id}`)
        .then((response) => {
            if(response.data.status){
                setUserInformation(response.data.result.user)
                let nevermissnew = false;
                let bootstyleiq = false;
                if(response.data.result.user.never_miss_new == 1){
                    nevermissnew = true;
                }

                if(response.data.result.user.boot_style_iq == 1){
                    bootstyleiq = true;
                }
                setFormData((prevData) => ({
                    ...prevData,
                    never_miss_new : nevermissnew,
                    boot_style_iq : bootstyleiq,
                }));
            }
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });    
        // console.log('form data : ', formData);
    }   
    }, [userSession]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsProcessing(true);
        console.log("Test @@ = ", formData);
        axios
        .post(`${BACKEND_URL}/api/V1/profile/email-preferences`,formData)
        .then((response) => {
            if(response.data.status){
                // const notifys = () => toast.success(response.data.message);
                // setNotify(notifys);
                toast.success(response.data.message, TOAST_CONFIG);
                setIsProcessing(false);
            }
        })
        .catch((error) => {
            setIsProcessing(false);
            console.error("Error fetching layout data:", error);
        });
    };

    return (
        
        <Box
            className="accDetailContainer"
            sx={{ display: profileMenuTab == 3 ? "flex" : "none" }}
        >
            
            <Box className="individualPreferenceBox">
                <Box className="checkBox">
                    {/* <Checkbox otherProps name="never_miss_new" onChange={e => this.handleChange(e)} /> */}
                    <input type="checkbox" name="never_miss_new" value={formData.never_miss_new} checked={ formData.never_miss_new == 1 ?  "checked" : "" } onChange={handleChange} />
                </Box>
                <Box className="textContent">
                    <Box className="heading">
                        <Typography className="infoHeader">
                            Never miss what's new
                        </Typography>
                    </Box>

                    <Box className="pInfo tInfo">
                        <Typography className="infoText">
                            Be the first to know about the latest arrivals from your favorite
                            designers every Monday, Wednesday and Friday – plus we’ll keep you
                            up-to-date on special promotions, sales and more.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Email Section */}
            <Box className="individualPreferenceBox">
                <Box className="checkBox">
                    {/* <Checkbox otherProps name="boot_style_iq" onChange={e => this.handleChange(e)} /> */}
                    <input type="checkbox" value={formData.boot_style_iq} checked={ formData.boot_style_iq == 1 ?  "checked" : "" } name="boot_style_iq" onChange={handleChange} />
                </Box>
                <Box className="textContent">
                    <Box className="heading">
                        <Typography className="infoHeader">Boost your style IQ</Typography>
                    </Box>

                    <Box className="pInfo tInfo">
                        <Typography className="infoText">
                            Get ahead of the curve with fashion updates, expert styling and
                            beauty advice, plus exclusive celebrity interviews, culture and
                            travel picks from the world of PORTER. We’ll also keep you
                            up-to-date on special promotions, sales and more.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box className="individualPreferenceBox" style={{ border : "unset", backgroundColor : "unset" }}>
                <Box className="textContent">
                    <Box className="heading">
                        <Button onClick={handleSubmit} type="submit">{isProcessing ? "Processing..." : "Update"}</Button>
                    </Box>
                </Box>
            </Box>

            {/* Email Section */}
        </Box>
    );
}
