import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import Payment from './Payment'; // Adjust the import based on your structure

//pages
import Home from "./Pages/Home";
import Product from "./Pages/Product";
import CaviarList from "./Pages/CaviarList";
import Accessories from "./Pages/Accessories";
import OscietraCaviar from "./Pages/OscietraCaviar";
import Blogs from "./Pages/Blogs";
import Blogs2 from "./Pages/Blogs2";
import Blogs3 from "./Pages/Blogs3";
import N25 from "./Pages/N25";
import ContactPage from "./Pages/Contact";
import N25Global from "./Pages/n25-global";
import GarethWard from "./Pages/GarethWard";
import RefaCagali from "./Pages/RafaCagali";
import Stories from "./Pages/Stories";
import Payment from "./Pages/Payment";
import OrderSuccess from "./Pages/OrderSuccess";
import Profile from "./Pages/Profile";
import LoyaltyRewards from "./Pages/LoyaltyRewards";
import LoginPage from "./Pages/Login";
import ForgetPasswordPage from "./Pages/ForgetPassword";
import SignupPage from "./Pages/Signup";
import OfferPage from "./Pages/OfferPage";
import ChangePasswordPage from "./Pages/ChangePassword";
import config from "./config";
import AccountPage from "./Pages/AccountPage";
import MyOrders from "./Components/MyOrders";
import TermConditionPage from "./Pages/TermCondition";
import PrivacyPolicyPage from "./Pages/PrivacyPolicy";
import ShippingInformationPage from "./Pages/ShippingInformation";
import PeoplePage from "./Pages/Peoples";
const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

export default function App() {
  const [cookiePop, setCookiePop] = useState(false);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Home cookiePop={cookiePop} setCookiePop={setCookiePop} />}
        />
        <Route
          path="/all-products"
          element={
            <Product cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/our-caviar"
          element={
            <CaviarList cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/our-accessories"
          element={
            <Accessories cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/product/:productid"
          element={
            <OscietraCaviar cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/blogs"
          element={<Blogs cookiePop={cookiePop} setCookiePop={setCookiePop} />}
        />
        <Route
          path="/blogs-2"
          element={<Blogs2 cookiePop={cookiePop} setCookiePop={setCookiePop} />}
        />
        <Route
          path="/blogs-3"
          element={<Blogs3 cookiePop={cookiePop} setCookiePop={setCookiePop} />}
        />
        <Route
          path="/n25"
          element={<N25 cookiePop={cookiePop} setCookiePop={setCookiePop} />}
        />
        <Route
          path="/contact"
          element={
            <ContactPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/n25-global"
          element={
            <N25Global cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/ambassador-gareth-ward"
          element={
            <GarethWard cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/n25-x-rafa-cagali-2-michelin-stars"
          element={
            <RefaCagali cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/our-Stories"
          element={
            <Stories cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/payment"
          element={
            <Elements stripe={stripePromise}>
              <Payment cookiePop={cookiePop} setCookiePop={setCookiePop} />
            </Elements>
          }
        />
        <Route
          path="/order-success/:orderid"
          element={
            <OrderSuccess cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/loyalty-rewards"
          element={<LoyaltyRewards />}
          cookiePop={cookiePop}
          setCookiePop={setCookiePop}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/account" element={<AccountPage />} />
        <Route
          path="/login"
          element={
            <LoginPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/forget-password"
          element={
            <ForgetPasswordPage
              cookiePop={cookiePop}
              setCookiePop={setCookiePop}
            />
          }
        />
        <Route
          path="/signup"
          element={
            <SignupPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/offer"
          element={
            <OfferPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/change-password/:token"
          element={
            <ChangePasswordPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <TermConditionPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PrivacyPolicyPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/shipping-delivery-information"
          element={
            <ShippingInformationPage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
        <Route
          path="/people"
          element={
            <PeoplePage cookiePop={cookiePop} setCookiePop={setCookiePop} />
          }
        />
      </Routes>
    </>
  );
}
