import React, { useEffect, useState } from "react";
import "./style.css";import config from "../../config";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import noResults from "../../Assets/Images/no-results.png";
import B2 from "../../Assets/Images/B2.png";
import dot from "../../Assets/Images/BlackDot.svg";
import { TOAST_CONFIG } from "../../lib/constants";

export default function MyOrders({ profileMenuTab, setProfileMenuTab }) {
    const BACKEND_URL = config.BACKEND_URL;
    const userSession = localStorage.getItem("User");
    const [notify, setNotify] = useState([]);
    const [orderStatus, setOrderStatus] = useState(false);
    const [orders, setOrders] = useState([]);
    const [orderStatusHistory, setOrderStatusHistory] = useState([]);
    const [orderStatusID, setOrderStatusID] = useState(0);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        orderInfo: [
            {
                Order_ID: "876546577",
                order_total: "$ 410",
                product_name: "Caviar",
                product_qty: "15",
                product_image: "",
            },
            {
                Order_ID: "876546577",
                order_total: "$ 410",
                product_name: "Caviar",
                product_qty: "15",
                product_image: "",
            },
            {
                Order_ID: "876546577",
                order_total: "$ 410",
                product_name: "Caviar",
                product_qty: "15",
                product_image: "",
            },
            {
                Order_ID: "876546577",
                order_total: "$ 410",
                product_name: "Caviar",
                product_qty: "15",
                product_image: "",
            },
            {
                Order_ID: "876546577",
                order_total: "$ 410",
                product_name: "Caviar",
                product_qty: "15",
                product_image: "",
            },
        ],
    });
    const toggleEditComponent = () => {
        setOrderStatus((prevState) => !prevState);
    };

    useEffect(() => {
        if (userSession) {
            const User = JSON.parse(userSession);
            setUserId(User.id)
            loadOrder(User.id); 
        }    
        
    }, []);

    const loadOrder = (userValue) => {
        const user_id = userValue;
        axios
        .get(`${BACKEND_URL}/api/V1/get-all-order/${user_id}`)
        .then((response) => {
            if(response.data.status){
                setOrders(response.data.result.order);
            }else{
                toast.error("Error fetching data",TOAST_CONFIG);
            }
        })
        .catch((error) => {
            toast.error(error.response.data.errors,TOAST_CONFIG);
            console.error("Error fetching layout data:", error.response.data.errors);
        });
    }
    
    const orderCancelBtn = (order_id) => {
        const postdata = {
            user_id : 193,
            order_id : order_id
        }
        // console.log('Post Data :', postdata)
        axios
        .post(`${BACKEND_URL}/api/V1/order/cancel`,postdata)
        .then((response) => {
            if(response.data.status){
                // const notifys = () => toast.success(response.data.message);
                // setNotify(notifys);
                toast.success(response.data.message,TOAST_CONFIG);
            }else{
                toast.error(response.data.message,TOAST_CONFIG);
            }
        })
        .catch((error) => {
            // const notifys = () => toast.error("Error fetching data");
            // setNotify(notifys);
            toast.error("Error fetching data",TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
        
    }

    const getOrderStatusHistory = (order_id) => {
        setOrderStatusID(order_id);
        axios
        .get(`${BACKEND_URL}/api/V1/order-status/history?order_id=${order_id}`)
        .then((response) => {
            if(response.data.status){
                loadOrder(userId);
                setOrderStatusHistory(response.data.result.order_status);
            }else{
                // const notifys = () => toast.error(response.data.message);
                // setNotify(notifys);
                toast.error(response.data.message,TOAST_CONFIG);
            }
        })
        .catch((error) => {
            // const notifys = () => toast.error("Error fetching data");
            // setNotify(notifys);
            toast.error("Error fetching data",TOAST_CONFIG);
            console.error("Error fetching layout data:", error);
        });
    }

    return (
        <Box className="accDetailContainer orderContainer" sx={{ display: profileMenuTab == 0 ? "flex" : "none" }}>
            {orders.length > 0 ? (
                <>
                    {orders.map((order, index) => (
                        <Box className="individualOrderContainer">
                            <Box className="oderBox" style={{ justifyContent : "unset" }}>
                                <Box className="orderInfo" key={index} style={{ width : "50%" }}>
                                    <Typography className="orderText">
                                        <span className="labelName">Order ID :</span>
                                        {order.code}
                                    </Typography>
                                    <Typography className="orderText">
                                        <span className="labelName">Order Total :</span>
                                        {order.grand_total}
                                    </Typography>
                                    <Typography className="orderText">
                                        <span className="labelName">Delivery Status :</span>
                                        {order.delivery_status}
                                    </Typography>                                    
                                </Box>
                                {order.orderdetails.map(((orderdetail, inx) => (
                                    <Box className="orderInfo" key={inx}>
                                        <Box className="imgBox">
                                            <img className="productImage" style={{ height : "200px" }} src={`${BACKEND_URL}${orderdetail.prod_image}`} alt="product" />
                                        </Box>
                                        <Typography className="orderText">
                                            <span className="labelName">Product Name :</span>
                                            {orderdetail.prod_name}
                                        </Typography>
                                        <Typography className="orderText">
                                            <span className="labelName">Quantity :</span>
                                            {orderdetail.quantity}
                                        </Typography>
                                    </Box>
                                )))}
                            </Box>
                            <Box className="statusBtnBox">
                                {/* <Button className="statusBtn" onClick={() => toggleEditComponent()}>Track Order</Button> */}
                                {
                                    order.delivery_status == 'cancelled' ? (
                                        <>
                                            <Button sx={{border:"1px solid #b0b0b0 !important"}} className="cancelBtn">Cancelled</Button>
                                        </> 
                                    ) : ""
                                }
                                {
                                    order.delivery_status == 'delivered' ? (
                                        <>
                                            <Button sx={{border:"1px solid #b0b0b0 !important"}} className="cancelBtn">Delivered</Button>
                                        </>
                                    ) : ""
                                }
                                {
                                    order.delivery_status == 'on_the_way' || order.delivery_status == 'pending' || order.delivery_status == 'picked_up' || order.delivery_status == 'confirmed' ? (
                                        <>
                                            <Button sx={{border:"1px solid #b0b0b0 !important"}} className="cancelBtn" onClick={() => orderCancelBtn(order.id)}>Order Cancel</Button>
                                        </>
                                    ) : ""
                                }
                                {
                                    order.total_status > 1 ? (
                                        <>
                                            <Button sx={{border:"1px solid #b0b0b0 !important"}} className="statusBtn" onClick={() => getOrderStatusHistory(order.id)}>Track Order</Button>
                                        </>
                                    ) : ""
                                }
                            </Box>
                            {
                                orderStatusID == order.id ? (
                                    <>
                                        <Box className="prodctOrderTItemBottom" >
                                            <Box className="progressBarBox">
                                                <Box className="progressBar" style={{ height: "100%" }}></Box>
                                            </Box>
                                            {orderStatusHistory.map(((orderstatus) => (
                                                <Box className="statusUpdateBox">
                                                    <img src={dot} className="dot" />
                                                    <p className='suTitle'>{orderstatus.status} <span> {orderstatus.date}</span></p>
                                                    <p className='suSubText1'>{orderstatus.remark}</p>
                                                    { orderstatus.status == 'Shipped' ? <p className='suSubText1'>{orderstatus.courier_name} - {orderstatus.aws_bill_no}</p> : "" }
                                                    
                                                    {/* <p className="suSubText1">Your Order has been placed</p>
                                                    <p className="suSubText2">{orderstatus.status}</p>
                                                    <Box className="gapB"></Box>
                                                    <p className="suSubText1">
                                                        {orderstatus.remark}
                                                    </p>
                                                    <p className="suSubText2">{orderstatus.date}</p> */}
                                                </Box>
                                            )))}
                                            {/* <Box className="statusUpdateBox">
                                                <img src={dot} className="dot" />
                                                <p className="labelName">
                                                    Order Confirmed{" "}
                                                    <span className="orderText">Sun, 25th Aug 2024</span>
                                                </p>
                                                <p className="suSubText1">Your Order has been placed</p>
                                                <p className="suSubText2">Sun, 25th Aug 2024 - 11:16 am</p>
                                                <Box className="gapB"></Box>
                                                <p className="suSubText1">
                                                    Your item has picked up by courier partner.
                                                </p>
                                                <p className="suSubText2">Sun, 25th Aug 2024 - 11:16 am</p>
                                            </Box>
                                            <Box className="statusUpdateBox">
                                                <img src={dot} className="dot" />
                                                <p className="labelName">
                                                    Shipped{" "}
                                                    <span className="orderText">Sun, 25th Aug 2024</span>
                                                </p>
                                                <p className="suSubText1">
                                                    Ekart Logistics - FMBC21245789532132
                                                </p>
                                                <p className="suSubText1">Your item has been shipped</p>
                                                <p className="suSubText2">Sun, 25th Aug 2024 - 11:16 am</p>
                                                <Box className="gapB"></Box>
                                                <p className="suSubText1">
                                                    Your item has been received in the hub nearest to you{" "}
                                                </p>
                                            </Box>
                                            <Box className="statusUpdateBox">
                                                <img src={dot} className="dot" />
                                                <p className="labelName">
                                                    Out For Delivery{" "}
                                                    <span className="orderText">Sun, 25th Aug 2024</span>
                                                </p>
                                                <p className="suSubText1">Your item is out for delivery</p>
                                                <p className="suSubText2">Sun, 25th Aug 2024 - 11:16 am</p>
                                            </Box>
                                            <Box className="statusUpdateBox">
                                                <img src={dot} className="dot" />
                                                <p className="labelName">
                                                    Delivered{" "}
                                                    <span className="orderText">Sun, 25th Aug 2024</span>
                                                </p>
                                                <p className="suSubText1">Your item is out for delived</p>
                                                <p className="suSubText2">Sun, 25th Aug 2024 - 11:16 am</p>
                                            </Box> */}
                                        </Box>
                                    </>
                                ) : ""
                            }
                                
                        </Box>
                    ))}
                </>
            ) : (
                <>
                    <img className="emptyIcon" src={noResults} alt="" />
                    <Typography className="profileHeaderL bVariant">
                        You don’t currently have any orders
                    </Typography>
                    <Typography className="profileHeaderM bVariant">
                        Once you have checked out, you can view and track your order here
                    </Typography>
                    <Box className="addLink">
                        <Button className="profileHeaderM bVariant" onClick={() => navigate("/our-accessories")}>Shop What’s New</Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
