import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ChangePasswordPage({ cookiePop, setCookiePop }) {
    const { token } = useParams();
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [notify, setNotify] = useState([]);
    const navigate = useNavigate();

    useEffect( () => {
        if(token) {
        checkEmailToken(token);
        }
      }, [token]);

      const checkEmailToken = async(token)=>{
        if(token!=='' && token!==undefined ){
          
         axios.post(
            `${BACKEND_URL}/api/V1/send-password-token`,
            { password_token: token }
          ).then(response => {
           
            if(response.data.status){
                setChangeData(prevState => ({ ...prevState, user_id: response.data.result.user_id }));
            }else{
                const notifys = () => toast.error(response.data.errors);
                setNotify(notifys);
            }
            
          }).catch(error => {
            console.error('Error fetching Token data:', error);
          }); 
    
        }
      }

    const initialChangeState = {
        user_id:0,
        new_password: "",
        confirm_password: "",
        current_password: "",
      };
    
      const [changeData, setChangeData] = useState(initialChangeState);

      const validationObj = {
        hasErr: false,
        current_password: {
          error: false,
          msg: "",
        },
        new_password: {
            error: false,
            msg: "",
          },
          confirm_password: {
            error: false,
            msg: "",
          },
      };
    
      const [validationErrors, setValidationErrors] = useState({
        ...validationObj,
      });
    
      const handleValidation = (formInput) => {
        formInput = formInput || changeData;
        let validationerr = { ...validationObj };
        if (formInput.current_password == "") {
            validationerr.hasErr = true;
            validationerr.current_password = {
              error: true,
              msg: "Current Password is required",
            };
          }
        if (formInput.new_password == "") {
            validationerr.hasErr = true;
            validationerr.new_password = {
              error: true,
              msg: "New Password is required",
            };
          }

          if (formInput.confirm_password == "") {
            validationerr.hasErr = true;
            validationerr.confirm_password = {
              error: true,
              msg: "Confirm Password is required",
            };
          }
    
        setValidationErrors(validationerr);
        if (validationerr.hasErr) {
          return false;
        }
        return true;
      };
    
      const setFormData = (e) => {
        let newState = {
          ...changeData,
          [e.target.name]: e.target.value,
        };
        setChangeData(newState);
        if (validationErrors.hasErr) {
          handleValidation(newState);
        }
      };


      let saveChange = async () => {
        if (!handleValidation()) {
          const notifys = () => toast.error("Correct form data!");
          setNotify(notifys);
          return false;
        }
        console.log("Final Submit @@ = ",changeData);
        if(changeData.current_password!=="" && changeData.new_password!=="" && changeData.confirm_password!=="" && (changeData.new_password===changeData.confirm_password)){
            axios.post(
              `${BACKEND_URL}/api/V1/update-password`,
              changeData
            ).then(response => {
                console.log("response @@ =",response);
              if(response.data.status){
                const notifys = () => toast.success(response.data.result);
                  setNotify(notifys);
              }else{
                const notifys = () => toast.error(response.data.errors);
                setNotify(notifys);
              }
      
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
            
          }
      };

    const redirectLogin = () => {
    navigate("/login");
    }

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
            <ToastContainer />
                <Box className="contactInputSection">
                    
                            <Box className="contactInfo">
                            <Typography className='textInfo1'>Change Password</Typography>
                            </Box>
                    
                    <Box className="contactInputBox">
                    
                        <Box className="contactFInBox">
                        <Box className="contactAddressBox">
                            <Typography className="inputLabel">CURRENT PASSWORD</Typography>
                                <input type="text" className='addressinput' placeholder='Current Password*' name="current_password"
                      value={changeData.current_password ? changeData.current_password : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.current_password.msg}</span>

                            </Box>
                            <Box className="contactAddressBox">
                            <Typography className="inputLabel">NEW PASSWORD</Typography>
                                <input type="text" className='addressinput' placeholder='New Password*' name="new_password"
                      value={changeData.new_password ? changeData.new_password : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.new_password.msg}</span>

                            </Box>
                            <Box className="contactAddressBox">
                            <Typography className="inputLabel">CONFIRM PASSWORD</Typography>
                                <input type="text" className='addressinput' placeholder='Confirm Password*' name="confirm_password"
                      value={changeData.confirm_password ? changeData.confirm_password : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.confirm_password.msg}</span>

                            </Box>

                            
                        </Box>

                        <Box className="inputMessageBox">

                            <Box sx={{gap:"32px"}} className="MailCheckBox MailCheckBoxL">
                                {/* <Box className={Fcheck ? "check checkActive" : "check"} onClick={() => setFCheck(!Fcheck)}></Box> */}
                                
                                <Typography style={{cursor:"pointer",color:"#4f33d1"}} onClick={() =>redirectLogin()}>Back to Login ?</Typography>
                            </Box>


                            <span style={{width:"82px"}} className='discoverBtn' onClick={() =>saveChange()} >
                                <Typography>Change</Typography>
                                <span className='dbsLine dbTops'></span>
                                <span className='dbsLine dbRighs'></span>
                                <span className='dbsLine dbBottoms'></span>
                                <span className='dbsLine dbLefts'></span>
                            </span>
                        </Box>
                    </Box>
                </Box>

                {/* mail section */}
                <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}
