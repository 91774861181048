import React, { useEffect, useState } from 'react';
import { useNavigate} from "react-router-dom";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LoginPage({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState([]);
    const navigate = useNavigate();

    const initialLoginState = {
        email: "",
        password:"",
      };
    
      const [loginData, setLoginData] = useState(initialLoginState);

      const validationObj = {
        hasErr: false,
        email: {
          error: false,
          msg: "",
        },
        password: {
          error: false,
          msg: "",
        },
      };
    
      const [validationErrors, setValidationErrors] = useState({
        ...validationObj,
      });
    
      const handleValidation = (formInput) => {
        formInput = formInput || loginData;
        let validationerr = { ...validationObj };
    
        if (formInput.email == "") {
          validationerr.hasErr = true;
          validationerr.email = {
            error: true,
            msg: "Email is required",
          };
        }
        
          if (formInput.password == "") {
            validationerr.hasErr = true;
            validationerr.password = {
              error: true,
              msg: "Password is required",
            };
          }
    
        setValidationErrors(validationerr);
        if (validationerr.hasErr) {
          return false;
        }
        return true;
      };
    
      const setFormData = (e) => {
        let newState = {
          ...loginData,
          [e.target.name]: e.target.value,
        };
        setLoginData(newState);
        if (validationErrors.hasErr) {
          handleValidation(newState);
        }
      };

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      let saveLogin = async () => {
        if (!handleValidation()) {
          const notifys = () => toast.error("Correct form data!");
          setNotify(notifys);
          return false;
        }
        console.log("Final Submit @@ = ",loginData);
        setLoading(true);
        if (
          loginData.email !== "" &&
          validateEmail(loginData.email) &&
          loginData.password !== ""
        ) {
          await axios
            .post(`${BACKEND_URL}/api/V1/login`, loginData)
            .then((res) => {
              // console.log("response @@ = ", res.data);
              if (res.data.status) {
                localStorage.setItem("User", JSON.stringify(res.data.result.user));
                localStorage.setItem("Token", res.data.result.token);
                const notifys = () => toast.success(res.data.result.message);
                  setNotify(notifys);
                  setTimeout(() => {
                    navigate("/profile");
                }, 1000);
              } else {
                if (!res.data.status && res.data.errors) {
                  const notifys = () => toast.error(res.data.errors);
                  setNotify(notifys);
                }
              }
            })
            .catch((error) => {
              if (error.errors) {
                console.log(error.errors);
              }
            });
        }
      };

      const redirectForgetPassword = () => {
        navigate("/forget-password");
      }

      const redirectSignup = () => {
        navigate("/signup");
      }
    
    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
            <ToastContainer />
                <Box className="contactInputSection">
                    
                            <Box className="contactInfo">
                            <Typography className='textInfo1'>Login</Typography>
                            </Box>
                    
                    <Box className="contactInputBox">
                    
                        <Box className="contactFInBox">
                            <Box className="contactAddressBox">
                            <Typography className="inputLabel">EMAIL</Typography>
                            <input type="text" className='addressinput' placeholder='Your email*' name="email"
                      value={loginData.email ? loginData.email : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.email.msg}</span>

                                <Typography className="inputLabel">PASSWORD</Typography>
                                <input type="text" className='addressinput' placeholder='Password*' name="password"
                      value={loginData.password ? loginData.password : "" }
                      onChange={setFormData} />
                      <span className="validationerr">{validationErrors.password.msg}</span>
                            </Box>

                            
                        </Box>

                        <Box className="inputMessageBox">

                            <Box sx={{gap:"32px"}} className="MailCheckBox MailCheckBoxL">
                                {/* <Box className={Fcheck ? "check checkActive" : "check"} onClick={() => setFCheck(!Fcheck)}></Box> */}
                                <Typography style={{cursor:"pointer",color:"#4f33d1 !important"}} onClick={() =>redirectForgetPassword()}>Forgot Password ?</Typography>
                                <Typography style={{cursor:"pointer",color:"#4f33d1 !important"}} onClick={() =>redirectSignup()}>Signup ?</Typography>
                            </Box>


                            <span className='discoverBtn' onClick={() =>saveLogin()} >
                                <Typography>Login</Typography>
                                <span className='dbsLine dbTops'></span>
                                <span className='dbsLine dbRighs'></span>
                                <span className='dbsLine dbBottoms'></span>
                                <span className='dbsLine dbLefts'></span>
                            </span>
                        </Box>
                    </Box>
                </Box>

                {/* mail section */}
                <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}
