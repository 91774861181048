import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CookiePop from "../../Components/CookiePop";
import NavBar from "../../Components/NavBar";
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import EmailCommunity from '../../Components/EmailCommunity';
import "./style.css";

export default function OfferPage({ cookiePop, setCookiePop }) {
    const [email, setEmail] = useState('');
    const [check, setCheck] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubscribe = () => {
        // Handle subscription logic
        alert(`Subscribed with email: ${email}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="OfferPage">
                <Box className="offerInfo">
                    <Typography className='textInfo1'>Exclusive Offers</Typography>
                    <Typography className='offerDescription'>
                        Join us and enjoy exclusive offers tailored just for you. Sign up below to stay updated!
                    </Typography>
                </Box>
                <Box className="offerDetails">
                    <Typography className='detailsHeader'>Current Offers:</Typography>
                    <Typography className='detailsInfo'>
                        - 20% off on your first order<br />
                        - Free shipping on orders over $50<br />
                        - Exclusive access to new products
                    </Typography>
                </Box>
                {/* mail section */}
                <EmailCommunity />
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                {/* mail section */}
                
            </Box>
            <Footer />
        </>
    );
}