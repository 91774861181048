import React, { useEffect, useState } from 'react';
import "./style.css"
import { useNavigate } from "react-router-dom";

//images
import accessImg1 from "../../Assets/Images/accessImg1.jpg";
import accessImg2 from "../../Assets/Images/accessImg2.jpg";
import accessImg3 from "../../Assets/Images/accessImg3.jpg";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import EmailCommunity from '../../Components/EmailCommunity';

export default function Accessories({ cookiePop, setCookiePop }) {
    const navigate = useNavigate();
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false)
    const [firstSection, setFirstSection] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/our-accessories/content`)
        .then((response) => {
            setFirstSection(response.data.result.content);
            setProducts(response.data.result.products)
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="productPage Accessories">
                    {firstSection.map((item) => {
                        const [beforeAccessories, afterAccessories] = item.title.split(/(Accessories \/ Delicacies)/);
                        const afterAccessoriesTrimmed = afterAccessories ? afterAccessories.trim() : '';
                        return(
                            <Box className='productHeaderBox'>
                                <Typography className='ambHeader' data-aos="fade-up">{beforeAccessories}
              <br />
              <span className="TimesNowText">{afterAccessoriesTrimmed}</span></Typography>
                            </Box>
                        )
                    })}
                <Box className="productListBox">
                    {products.map((item) => {
    
                        return(
                            <Box className="productCard" onClick={() => navigate(`/product/${item.id}`)}>
                                <Box className="productImgBox">
                                    <img src={`${BACKEND_URL}${item.prod_image}`} />
                                </Box>
                                <Box className="shopSLabelTextBox" data-aos="fade-up">
                                <span>{item.prod_name}</span>
                                </Box>
                                <Typography className='pCardBText' data-aos="fade-up">{item.price} €</Typography>
                            </Box>
                        )
                    })}
                </Box>

                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
