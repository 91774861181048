import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, TextField, Button, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

export default function PrivacySettings({ profileMenuTab, setProfileMenuTab }) {
    const [editingSections, setEditingSections] = useState([false, false, false]);

    const toggleEditComponent = (index) => {
        const newEditingSection = [...editingSections];
        newEditingSection[index] = !newEditingSection[index];
        setEditingSections(newEditingSection);
    };

    const [formData, setFormData] = useState({
        personalInfo: {
            title: "Mr",
            firstName: "Gilfoyle",
            lastName: "Keller",
            day: "12",
            month: "Feb",
            year: "1993",
        },
        email: "jacobsmithtemp@gmail.com",
        password: "●●●●●●●●●●●●",
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted", formData);
        // Perform form submission logic here
    };

    return (
        <Box
            className="accDetailContainer"
            sx={{ display: profileMenuTab == 4 ? "flex" : "none" }}
        >
            <Box className="individualPageContainer">
                <Box className="heading">
                    <Typography className="infoHeader">
                        Account Terms and Conditions
                    </Typography>
                </Box>

                <Box className="pInfo tInfo">
                    <Link to="/terms-and-conditions">
                        <Typography
                            className="infoText"
                            style={{ textDecoration: "underline" }}
                        >
                            Read the Terms and Conditions
                        </Typography>
                    </Link>
                </Box>
            </Box>

            {/* Email Section */}
            <Box className="individualPageContainer">
                <Box className="heading">
                    <Typography className="infoHeader">Privacy Policy</Typography>
                </Box>

                <Box className="pInfo tInfo">
                    <Link to="/privacy-policy">
                        <Typography
                            className="infoText"
                            style={{ textDecoration: "underline" }}
                        >
                            Read our Privacy Policy
                        </Typography>
                    </Link>
                </Box>
            </Box>

            {/* Email Section */}
            <Box className="blankContainer">
                {/* <Box className="heading">
                    <Typography className="infoHeader">Password</Typography>
                </Box>

                {editingSections[2] ? (
                    <>
                        <form onSubmit={handleSubmit}>
                            <Box className="uName">
                                <TextField
                                    label="Password"
                                    name="password"
                                    value={formData.email}
                                    onChange={(e) => handleChange(e, "password")}
                                />
                            </Box>
                            <Button type="submit">Update</Button>
                        </form>
                        <Box className="cancelText" onClick={() => toggleEditComponent(2)}>
                            Cancel
                        </Box>
                    </>
                ) : (
                    <>
                        <Box className="pInfo">
                            <Typography className="infoText">●●●●●●●●●●●●</Typography>
                        </Box>
                        <Box className="editText" onClick={() => toggleEditComponent(2)}>
                            Edit
                        </Box>
                    </>
                )} */}
            </Box>
        </Box>
    );
}
