import React, { useEffect, useState } from 'react';
import "./style.css"

//images
import productImg1 from "../../Assets/Images/product1.png";
import productImg2 from "../../Assets/Images/product2.png";
import productImg3 from "../../Assets/Images/product3.png";


//components
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import CookiePop from "../../Components/CookiePop"
import config from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { whiteArrow } from "../../Assets/Data";
import EmailCommunity from '../../Components/EmailCommunity';

export default function Product({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false)
    const [firstSection, setFirstSection] = useState([]);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/shop-now/content`)
        .then((response) => {
            setFirstSection(response.data.result.content);
            setCategories(response.data.result.catgories);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    const productCard = ({ img, bText1, bText2 }) => {
        return (
            <Box className="productCard">
                <Box className="productImgBox">
                    <img src={img} />
                </Box>
                <Box className="shopSLabelTextBox" data-aos="fade-up">
                    <span>{bText1}</span>
                    <span className='ssltbLine'></span>
                </Box>
                <Typography className='pCardBText' data-aos="fade-up">{bText2}</Typography>
            </Box>
        )
    }

    return (
        <>
            <NavBar />
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <Box className="productPage">
                {firstSection.map((item) => {
                    return(
                        <Box className='productHeaderBox'>
                            <Typography className='ambHeader' data-aos="fade-up">{item.title}</Typography>
                            <Typography className='productPageSuhHeader' data-aos="fade-up">{item.content_2}</Typography>
                            <Typography className='productPageSuhHeader' data-aos="fade-up">{item.content}</Typography>
                        </Box>
                    )
                })}
                <Box sx={{cursor:"pointer"}} className="productListBox">
                    {categories.map((item) => {
                        return(
                                <Box className="productCard" onClick={() => navigate(`${item.link}`)}>
                                    <Box className="productImgBox">
                                        <img src={`${BACKEND_URL}${item.image}`} />
                                    </Box>
                                    <Box className="shopSLabelTextBox" data-aos="fade-up">
                                        <span>DISCOVER</span>
                                        <span className='ssltbLine'></span>
                                    </Box>
                                    <Typography className='pCardBText' data-aos="fade-up">{item.title}</Typography>
                                </Box>
                        )
                    })}
                    {/*productCard({
                        img: productImg1,
                        bText1: "DISCOVER",
                        bText2: "N25 Caviar"
                    })}
                    {productCard({
                        img: productImg2,
                        bText1: "DISCOVER",
                        bText2: "Accessories/Delicacies"
                    })}
                    {productCard({
                        img: productImg3,
                        bText1: "DISCOVER",
                        bText2: "Wines / Champagne"
                    })*/}
                </Box>

                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                            <Typography>Terms and Conditions</Typography>
                            <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />

            </Box>
            <Footer />
        </>
    )
}
