import oscietraA from "../../Assets/Images/Caviar/oscietraA.png";
import oscietraB from "../../Assets/Images/Caviar/oscietraB.png";
import schrenckiiA from "../../Assets/Images/Caviar/schrenckiiA.png";
import schrenckiiB from "../../Assets/Images/Caviar/schrenckiiB.png";
import hybridA from "../../Assets/Images/Caviar/hybridA.png";
import hybridB from "../../Assets/Images/Caviar/hybridB.png";
import kalugaA from "../../Assets/Images/Caviar/kalugaA.png";
import kalugaB from "../../Assets/Images/Caviar/kalugaB.png";
import belugaA from "../../Assets/Images/Caviar/belugaA.png";
import belugaB from "../../Assets/Images/Caviar/belugaB.png";
import almasA from "../../Assets/Images/Caviar/almasA.png";
import almasB from "../../Assets/Images/Caviar/almasB.png";
import editionA from "../../Assets/Images/Caviar/editionA.png";
import editionB from "../../Assets/Images/Caviar/editionB.png";
import albinoA from "../../Assets/Images/Caviar/albinoA.png";
import albinoB from "../../Assets/Images/Caviar/albinoB.png";

import storiesImg1 from "../Images/storeImg1.jpg";
import storiesImg2 from "../Images/storeImg2.jpg";
import storiesImg3 from "../Images/storeImg3.jpg";
import storiesImg4 from "../Images/storeImg4.jpg";
import storiesImg5 from "../Images/storeImg5.jpg";
import storiesImg6 from "../Images/storeImg6.jpg";

import ovCardImg1 from "../../Assets/Images/ovCardImg1.jpg";
import ovCardImg2 from "../../Assets/Images/ovCardImg2.jpg";
import ovCardImg3 from "../../Assets/Images/ovCardImg3.jpg";
import ovCardImg4 from "../../Assets/Images/ovCardImg4.jpg";

export const Clogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 166 16"
    onClick={() => (window.location.href = "/")}
    style={{ cursor: "pointer" }}
  >
    <path
      fill="#fff"
      d="M14.426 15.181h-1.842L3.164 5.134v10.05H.5V.293h1.842l9.421 10.245V.295h2.665V15.18h-.002ZM30.294 15.181H19.972v-.94l5.426-5.936c1.076-1.194 2.017-2.115 2.017-3.643 0-1.41-.96-2.35-2.37-2.35-1.665 0-2.741 1.254-2.84 2.939l-2.448-.49C19.914 1.822 22.107 0 25.045 0c2.939 0 4.995 1.842 4.995 4.623 0 1.998-.862 3.172-2.84 5.406l-2.448 2.742h5.544v2.409l-.002.001ZM41.498 5.622c2.998 0 5.015 1.862 5.015 4.779s-2.33 5.093-5.406 5.093c-2.801 0-5.485-1.783-5.485-5.133l2.566-.51c0 2.215 1.527 3.252 2.978 3.252 1.45 0 2.8-1.057 2.8-2.665 0-1.468-1.076-2.448-2.665-2.448h-5.093L37.462.293h8.598v2.409h-6.463l-.49 2.919h2.39l.001.001ZM72.272 9.11l2.587.53c-.744 3.721-3.683 5.856-7.21 5.856-4.31 0-7.677-3.389-7.677-7.736 0-4.348 3.37-7.758 7.678-7.758 3.524 0 6.443 2.175 7.209 5.68l-2.587.724c-.588-2.625-2.428-3.899-4.622-3.899-2.801 0-4.995 2.273-4.995 5.25 0 2.975 2.194 5.248 4.995 5.248 2.194 0 4.034-1.254 4.622-3.898v.003ZM104.653 15.181h-1.783L96.034.295h2.939l4.778 10.52 4.798-10.52h2.939l-6.837 14.886h.002ZM116.622 15.181V.295h2.665V15.18h-2.665ZM155.465 15.181l-3.701-3.544h-3.938v3.544h-2.665V.295h6.347c3.721 0 6.386 2.273 6.386 5.66 0 2.33-1.254 4.133-3.232 5.034l4.291 4.192h-3.488Zm-3.955-5.955c2.135 0 3.741-1.313 3.741-3.27 0-1.96-1.606-3.252-3.741-3.252h-3.683v6.522h3.683ZM133.272.295h-2.094l-6.943 14.865h2.973l1.582-3.541h6.871l1.583 3.54h2.972L133.272.296Zm-3.407 8.918 2.37-5.298 2.353 5.298h-4.723ZM87.669.295h-2.095L78.632 15.16h2.972l1.583-3.541h6.87l1.584 3.54h2.972L87.669.296Zm-3.407 8.918 2.37-5.298 2.353 5.298H84.262ZM163.524 3.954a1.976 1.976 0 1 0 0-3.952 1.976 1.976 0 0 0 0 3.952Z"
    ></path>
  </svg>
);

export const UserIcon = (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ffffff99"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
        stroke="#FFFFFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
    </g>
  </svg>
);

export const whiteArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="m15.707 15.707-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L11 15.586V6a1 1 0 0 1 2 0v9.586l1.293-1.293a1 1 0 0 1 1.414 1.414z"
      data-name="Down"
    />
  </svg>
);

export const caviarList = [
  {
    imgA: oscietraB,
    imgB: oscietraA,
    title: "N25 Oscietra Caviar",
    price: "from £90.00",
  },
  {
    imgA: schrenckiiB,
    imgB: schrenckiiA,
    title: "N25 Schrenckii Caviar",
    price: "from £90.00",
  },
  {
    imgA: hybridB,
    imgB: hybridA,
    title: "N25 Kaluga Hybrid Caviar",
    price: "from £90.00",
  },
  {
    imgA: kalugaB,
    imgB: kalugaA,
    title: "N25 Kaluga Caviar",
    price: "from £180.00",
  },
  {
    imgA: belugaB,
    imgB: belugaA,
    title: "N25 Beluga Caviar",
    price: "from £258.00",
  },
  {
    imgA: almasB,
    imgB: almasA,
    title: "N25 Almas Caviar",
    price: "from £515.00",
  },
  {
    imgA: editionB,
    imgB: editionA,
    title: "N25 XXL Edition Caviar",
    price: "from £515.00",
  },
  {
    imgA: albinoB,
    imgB: albinoA,
    title: "N25 Albino Beluga Caviar",
    price: "from £600.00",
  },
];

export const NgData = [
  {
    description: "Europe",
    lng: 15.2541346595219,
    lat: 54.52804459119048,
    title: "N25 HQ",
    email: "info@n25caviar.com",
    phone: "+49 89 215280980",
  },

  {
    description: "Germany",
    lng: 10.380840857872201,
    lat: 51.09069569529872,
    title: "N25 HQ - German",
    email: "info@n25caviar.com",
    phone: "+49 89 215280980",
  },
  {
    description: "Spain",
    lng: -3.39076,
    lat: 39.471483,
    title: "N25 Barcelona",
    email: "info@n25caviar.com",
    phone: "Tel: +34 932 718 847",
  },
  {
    description: "Switzerland",
    lng: 7.949817479998587,
    lat: 46.79999316334898,
    title: "coming soon",
    email: "info@n25caviar.com",
    phone: "+49 89 215280980",
  },
  {
    description: "England (UK)",
    lng: -1.7866100313122533,
    lat: 52.10853134683481,
    title: "N25 London",
    email: "info@n25caviar.com",
    phone: " +44 20 3318 0081",
  },
  {
    description: "France",
    lng: 2.4565676176781506,
    lat: 46.632128631653956,
    title: "N25 HQ",
    email: "info@n25caviar.com",
    phone: "Tel: +34 932 718 847",
  },
  {
    description: "Japan",
    lng: 138.2545025167391,
    lat: 36.66073757249104,
    title: "N25 Tokyo",
    email: "info@n25caviar.com",
    phone: "+81 80-1162-7881",
  },
  {
    description: "Thailand",
    lng: 101.1174201906819,
    lat: 15.434614668808866,
    title: "N25 Bangkok",
    email: "info@n25caviar.com",
    phone: " +65 9053 9590",
  },
  {
    description: "Singapore",
    lng: 103.798917,
    lat: 1.377149,
    title: "Gourmet Partner Singapore",
    email: "info@n25caviar.com",
    phone: "+65 8869 6093",
  },
  {
    description: "Hong Kong",
    lng: 114.17219137646303,
    lat: 22.32496175070071,
    title: "Waves Pacific Hong Kong",
    email: "info@n25caviar.com",
    phone: "+(852) 2328 3556",
  },
  {
    description: "Australia",
    lng: 134.35178154553284,
    lat: -24.99921107898593,
    title: "Two Providores Sydney",
    email: "info@n25caviar.com",
    phone: "(02)9565 2255",
  },
  {
    description: "Dubai",
    lng: 55.27081264255491,
    lat: 25.204880458852536,
    title: "N25 Dubai",
    email: "info@n25caviar.com",
    phone: "+49 89 215280980",
  },
];

export const StoriesData = [
  {
    img: storiesImg1,
    data: "08 / 8 / 2024",
    title: "Seafood Recipe: Chilean Seabass",
  },
  {
    img: storiesImg2,
    data: "12 / 6 / 2024",
    title: "Ambassador in Conversation: Gareth Ward",
  },
  {
    img: storiesImg2,
    data: "12 / 6 / 2024",
    title: "Ambassador in Conversation: Gareth Ward",
    disibal: true,
  },
  {
    img: storiesImg3,
    data: "15 / 3 / 2024",
    title: "Easter Recipe: Caviar Egg",
  },
  {
    img: storiesImg2,
    data: "12 / 6 / 2024",
    title: "Ambassador in Conversation: Gareth Ward",
    disibal: true,
  },
  {
    img: storiesImg4,
    data: "08 / 3 / 2024",
    title: "Spotlight On: Stefanie Hering",
  },
  {
    img: storiesImg5,
    data: "05 / 2 / 2024",
    title: "N25 Chef Social at Bibi by Chet Sharma",
  },
  {
    img: storiesImg6,
    data: "02 / 2 / 2024",
    title: "Land and Sea",
  },
];

export const ProductService = {
  getProductsData() {
    return [
      {
        img: ovCardImg1,
        title: "Origin",
        summery:
          "The name N25 Caviar embodies the exceptional origin that makes our caviar so unique.",
      },
      {
        img: ovCardImg2,
        title: "Our aging process",
        summery:
          "With years of experience, we specialize in selecting and aging caviar bringing out a unique umami flavour profile.",
      },
      {
        img: ovCardImg3,
        title: "German quality control",
        summery:
          "At N25, we prioritize the highest standards of German quality control.",
      },
      {
        img: ovCardImg4,
        title: "Sustainability",
        summery: "Sustainability is a core value that guides our practices.",
        last: true,
      },
    ];
  },
  getProductsSmall() {
    return Promise.resolve(this.getProductsData().slice(0, 10));
  },
};
