import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { toast } from "react-toastify";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import crossIcon from "../../Assets/Images/crossIcon.png";
import cancelIcon from "../../Assets/Images/cancelIcon2.jpg";
import EmailCommunity from '../../Components/EmailCommunity';
import { TOAST_CONFIG } from "../../lib/constants";

export default function Payment({ cookiePop, setCookiePop }) {
    const userSession = localStorage.getItem("User");
    const BACKEND_URL = config.BACKEND_URL;
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [Fcheck, setFCheck] = useState(false);
    const [Scheck, setSCheck] = useState(false);
    const [cartItem, setCartItem] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [totalCartItem, setTotalCartItem] = useState();
    const [paymentError, setPaymentError] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [isCouponProcessing, setIsCouponProcessing] = useState(false);
    const [borderColor, setBorderColor] = useState("1px solid #ffffff80");
    const [applyCoupon, setapplyCoupon] = useState(false);
    const [discountAmt, setDiscountAmt] = useState();

    useEffect(() => {
        if (userSession) {
        const User = JSON.parse(userSession);
        const postData = {
            user_id : User.id
        };
        getCartItem(postData)
    }
    }, [userSession]);

    const getCartItem = (postData) => {
        axios
        .post(`${BACKEND_URL}/api/V1/user-cart-data`,postData)
        .then((response) => {
            setCartItem(response.data.result.cartitem);
            setTotalAmount(response.data.result.total_amount)
            setTotalCartItem(response.data.result.cartitem.length)
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
    const removeCartItem = (cartid) => {
        if (userSession) {
        const User2 = JSON.parse(userSession);
        const postData = {
            cart_id : cartid
        };

        axios
        .post(`${BACKEND_URL}/api/V1/remove-cart-item`,postData)
        .then((response) => {
            const postData = {
                user_id : User2.id
            };
            getCartItem(postData);
            toast(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
    }

    const handleUpCount = (cartid, qty) => {
        if (userSession) {
        const User3 = JSON.parse(userSession);
        const countVal = qty + 1;
        const postData = {
            cart_id : cartid,
            qty : countVal
        };
        axios
        .post(`${BACKEND_URL}/api/V1/update-qty`,postData)
        .then((response) => {
            const userPostData = {
                user_id : User3.id
            };
            getCartItem(userPostData);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
    };

    const handleDownCount = (cartid, qty) => {
        qty = qty;
        if (qty === 1) {
            return qty;
        } else {
            qty = qty - 1;
        }

        const postData = {
            cart_id : cartid,
            qty : qty
        };
        changeCartItemQty(postData);
    };

    const changeCartItemQty = (postData) => {
        if (userSession) {
        const User4 = JSON.parse(userSession);
        axios
        .post(`${BACKEND_URL}/api/V1/update-qty`,postData)
        .then((response) => {
            const userPostData = {
                user_id : User4.id
            };
            getCartItem(userPostData);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userSession) {
        const User5 = JSON.parse(userSession);
        
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);
        try {
            const { token, error } = await stripe.createToken(elements.getElement(CardElement));
            const payload = {
                type: "stripe",
                token: token,
                user_id: User5.id,
                totalAmount: totalAmount,
                couponCode: couponCode,
                discountAmt: discountAmt,
            }
            console.log("Payload Value @@ = ",payload);
            if (error) {
                console.error(error);
                setPaymentError(error.message);
                setIsProcessing(false); // Re-enable button
            } else {
                axios
                .post(
                    `${BACKEND_URL}/api/V1/process-payment`,
                    payload
                )
                .then((response) => {
                    if (response.data.status === true) {
                        console.log(response);
                        navigate(`/order-success/${response.data.result.data.orderId}`);
                    }else{
                        setPaymentError(response.data.errors);
                        toast(response.data.errors, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            onClose: () => {
                                navigate("/payment-failed");
                                setIsProcessing(false); // Re-enable button
                            },
                        });
                    }
                })
                .catch((error) => {
                    setPaymentError(error);
                    toast(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        onClose: () => {
                            navigate("/payment-failed");
                            setIsProcessing(false); // Re-enable button
                        },
                    });
                    console.error("Error fetching layout data:", error);
                });
            }
        } catch (error) {
            console.error(error);
            setPaymentError(error.message);
            setIsProcessing(false); // Re-enable button
          }
        }
    }

    const setCouponCodeValue = (e) => {
        setCouponCode(e.target.value);
      };

      const applyCouponCode = () => {
        if (couponCode == "") {
            toast.error("Coupon code is required",TOAST_CONFIG);
            return false;
          }
        setIsCouponProcessing(true);
        const User7 = JSON.parse(userSession);
        if (User7.id) {
          const postData = {
            Coupon: couponCode,
            UserId: User7.id,
          };
          axios
            .post(`${BACKEND_URL}/api/V1/checkCoupon`, postData)
            .then((response) => {
                console.log("Coupon Response @@ = ",response.data);
              if (response.data.status) {
                toast.success(response.data.message, TOAST_CONFIG);
                setCouponCode("");
                setIsCouponProcessing(false);
                setTotalAmount(response.data.result.totalAmount);
                setDiscountAmt(response.data.result.coupon_discount);
            
              } else {
                setIsCouponProcessing(false);
                toast.error(response.data.message, TOAST_CONFIG);
                
              }
            })
            .catch((error) => {
              setIsCouponProcessing(false);
              toast.error("Error fetching data",TOAST_CONFIG);
              console.error("Error fetching data:", error);
            });
        }
      };

    const DARK_CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
            base: {
                // backgroundColor: "rgb(31 41 55)",
                iconColor: "#6D28D9",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",

                ":-webkit-autofill": {
                    color: "#fce883",
                },
                "::placeholder": {
                    color: "#D1D5DB",
                },
            },
            invalid: {
                iconColor: "#ef2961",
                color: "#ef2961",
            },
        },
    };
    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
            
                <Box className="contactInputSection">
                    
                        <Box className="contactInfo cartInnerBox">
                            <Box className="cartNav">
                                {/*<Box className="cartCrossBtn" onClick={() => setCartPop(false)}>
                                    <img src={crossIcon} />
                                </Box>*/}
                                {/* <Typography style={{color:"#fff"}}>Cart {totalCartItem}</Typography> */}
                            </Box>
                            <Box className="cartListBox cart-list-box">
                                {cartItem.map((item) => {
                                    return(
                                        <Box className="productItem">
                                            <Box sx={{background:"none"}} className="cProductImg">
                                                <img src={`${BACKEND_URL}${item.image}`} />
                                            </Box>
                                            <Box className="productItemTextBox">
                                                <Typography className='paymentProductTitle'>{item.prod_name}</Typography>
                                                <Box className="productBtnBox">
                                                    <Typography className='paymentProductTitle'>£ {item.price}</Typography>
                                                    <Box sx={{backgroundColor:"#0e0e0e !important"}} className="inputNumber">
                                                        <button style={{color:"#fff",backgroundColor:"#0e0e0e"}} type="button" onClick={() => handleDownCount(item.id,item.qty)} className='paymentButton'>
                                                            -
                                                        </button>
                                                        <input style={{color:"#fff",backgroundColor:"#0e0e0e"}} type="text" name="" id="" value={item.qty || 1} />
                                                        <button style={{color:"#fff",backgroundColor:"#0e0e0e"}} type="button" onClick={() => handleUpCount(item.id,item.qty)} className='paymentButton'>
                                                            +
                                                        </button>
                                                    </Box>
                                                    <Box style={{ backgroundColor: '#0e0e0e'}} className="cartCrossBtn paymentButton" onClick={() => removeCartItem(item.id)}>
                                                        <img src={cancelIcon} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box onClick={() => setapplyCoupon(!applyCoupon)} sx={{cursor:"pointer"}}><Typography style={{paddingBottom:"16px"}}>Apply Coupon</Typography></Box>
                            <Box style={{display: applyCoupon ? "flex" : "none"}} className="couponCodeForm"><Box><input
                                type="text"
                                placeholder="Enter coupon code"
                                style={{height: "40px", border: borderColor,backgroundColor:"#0e0e0e" }}
                                onChange={(e) => setCouponCodeValue(e)}
                            /></Box><button
                            onClick={() => applyCouponCode()}
                            style={{
                              color: "#fff",
                              backgroundColor:"#0e0e0e",
                              padding: ".47rem 2rem",
                              borderRadius: ".25rem",
                              fontSize: ".875rem",
                              fontWeight: "400",
                              cursor: "pointer",
                            }} 
                            className='paymentButton'
                          >
                            {isCouponProcessing ? "Processing..." : "Apply"}
                          </button></Box>
                            <Box className="cartTotalPBox">
                                <Typography className='total-lavel'>Cart total</Typography>
                                <Typography className='total-value'>£ {totalAmount}</Typography>
                            </Box>
                        </Box>

                        <Box className="contactInputBox">
                            <form className="StripeForm" onSubmit={handleSubmit}>
                                <CardElement options={DARK_CARD_OPTIONS} />
                                <button style={{marginTop:"28px",padding:"5px",backgroundColor:"#0e0e0e"}} type="submit" disabled={!stripe || isProcessing} className='paymentButton'>
                                    <Typography sx={{color:"#fff"}}>{isProcessing ? "Processing..." : "Pay"}</Typography>
                                    <span className='dbsLine dbTops'></span>
                                    <span className='dbsLine dbRighs'></span>
                                    <span className='dbsLine dbBottoms'></span>
                                    <span className='dbsLine dbLefts'></span>
                                </button>
                                {/*<button
                                    disabled={!stripe || isProcessing}
                                    className="button discoverBtn"
                                    type="submit"
                                >
                                    {isProcessing ? "Processing..." : "Pay"}
                                </button>*/}
                            </form>
                        </Box>
                    
                </Box>
                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}