import React, { useEffect, useState } from "react";
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { TOAST_CONFIG } from "../../lib/constants";

//images
import backArrow from "../../Assets/Images/back-arrow.png";
// import mainImage from "../../Assets/Images/n25-oscietra-caviar-main-img.png";
// import mainImageTop from "../../Assets/Images/n25-oscietra-caviar-top-img.png";
// import mainImageBot from "../../Assets/Images/n25-oscietra-caviar-bot-img.png";
import dotFill from "../../Assets/Images/icon-dot.png";
import dotOutline from "../../Assets/Images/icon-dot-outline.png";
import iconPlus from "../../Assets/Images/icon-plus.png";
import iconMinus from "../../Assets/Images/icon-minus.png";

//components
import CookiePop from "../../Components/CookiePop";
import {
    Box,
    Typography,
    Grid,
    Link,
    Divider,
    ToggleButtonGroup,
    ToggleButton,
    Button,
} from "@mui/material";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import EmailCommunity from "../../Components/EmailCommunity";

export default function OscietraCaviar({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const { productid } = useParams();
    
    const [check, setCheck] = useState(false);
    var CartData = sessionStorage.getItem("Cart");
    // const [alignment, setAlignment] = React.useState("30g");
    const [alignment] = React.useState("30g");
    const [bottomCarosel, setBottomCarosel] = useState();
    const [countVal, setCountVal] = useState(1);
    const [product, setProduct] = useState([]);
    const [variations, setVariations] = useState([]);
    const [galleryImage, setGalleryImage] = useState([]);
    const [nutritional, setNutritional] = useState([]);
    const [productId, setProductId] = useState(null);
    const userSession = localStorage.getItem("User");
    const [checkLogin, setCheckLogin] = useState(false);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState("");

    useEffect(() => {
        if (userSession !== undefined && userSession !== null) {
            console.log("userSession @@ =", userSession);
            setUserId(userSession.id);
            setCheckLogin(true);
            
        } else {
            setCheckLogin(false);
        }
    }, [userSession]);

    const handleCaroselClick = (index) => {
        if (bottomCarosel === index) {
            setBottomCarosel(null);
        } else {
            setBottomCarosel(index);
        }
    };

    const handleUpCount = () => {
        setCountVal(countVal + 1);
    };

    const handleDownCount = () => {
        if (countVal === 1) {
            return;
        } else {
            setCountVal(countVal - 1);
        }
    };

    const addGiftCard = () => {
        var CartID = "";
        if (userSession) {
        const User = JSON.parse(userSession);
        const CardData = {
            price : product.price,
            user_id : User.id,
            product_id  : productId,
            discount : 0,
            coupon_applied : 0,
            quantity : countVal
        };

        if (CartData !== null) {
            if (typeof CartData == "string") {
                CartData = JSON.parse(CartData);
            }
            CartID = CartData.CartID;
        }
        // const postData = JSON.parse(CardData);
        console.log("Cart Data", CardData);
        axios
        .post(`${BACKEND_URL}/api/V1/addCart`, CardData)
        .then((response) => {
            if (response.data.status) {
                var cart_data = {};
                //console.log("CartData2=>",CartData)
                if (CartData === null) {
                    CartData = { CartID: response.data.result.CartID };
                } else {
                    //console.log(CartData,response.data.result.CartID )
                    if (typeof CartData == "string") {
                        CartData = JSON.parse(CartData);
                    }
                    CartData.CartID = response.data.result.CartID;
                }
                toast.success("Product added successfully in cart", TOAST_CONFIG);
                sessionStorage.setItem("Cart", JSON.stringify(CartData));
                sessionStorage.removeItem("GiftCard");
                console.log("Cart Item Vlaue",sessionStorage.getItem("Cart"));
                // navigate("/cart");
            } else {
                toast(response.messsage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
            toast(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }
    }

    useEffect(() => {
        axios
        .get(`${BACKEND_URL}/api/V1/our-caviar/${productid}`)
        .then((response) => {            
            const products = response.data.result.product;
            setProduct(products)
            setVariations(products.variations)            
            setGalleryImage(products.gallery_image)
            setNutritional(products.nutritional_values);
            setProductId(products.id);
            const defaultSelectedItem = products.variations.find(item1 => item1.stock !== 0)?.name;
            setSelectedItem(defaultSelectedItem || '');
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    console.log('variations', variations);
    console.log('galleryImage', galleryImage);
    console.log('nutritional', nutritional);

    const redirectLogin = () => {
        navigate("/login");
      }

    const handleToggle = (itemName, stock) => {
    if (stock !== 0) { 
        setSelectedItem(itemName === selectedItem ? null : itemName); 
    }
    };

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="productPageSection OscietraCaviarPage">
                <Box className="productDetailsSection">
                    <Link to={"/our-accessories"} className="backtoprevPage" onClick={() => navigate("/our-accessories")}>
                        <img src={backArrow} alt="back arrow"/>
                    </Link>
                    <Grid container>
                        <Grid item md={6} className="productSlider">
                            <Carousel axis="vertical">
                                <div>
                                    <img src={`${BACKEND_URL}${product?.image}`} alt="main product" />
                                </div>
                                {Array.isArray(galleryImage) && galleryImage.length > 0 ? (
    galleryImage.map((item, index) => (
        <div key={index}> {/* Use a unique key for each item */}
            {item.prod_image ? (
                <img src={`${BACKEND_URL}${item.prod_image}`} alt="product gallery" />
            ) : (
                <div key={index}></div> // Fallback if prod_image is not defined
            )}
        </div>
    ))
) : (
    <div></div> // Message when the array is empty
)}
                                {/*<div>
                                    <img src={mainImageTop} />
                                </div>
                                <div>
                                    <img src={mainImageBot} />
                                </div>*/}
                            </Carousel>
                        </Grid>
                        <Grid item md={6} className="productDetails">
                            <Typography variant="h2" gutterBottom>
                                {product?.name}
                            </Typography>
                            {product?.categorie === 'caviar'  && (
                                <Typography sx={{ color: "#fffc" }} variant="body1" gutterBottom>
                                    {product?.description}
                                </Typography>
                            )}
                            <Divider />
                            {product?.categorie === 'caviar'  && (
                                <Box className="flexListing">
                                    <ul>
                                        <li>
                                            <span>Species:</span> {product?.species}
                                        </li>
                                        <li>
                                            <span>Origin:</span> {product?.origin}
                                        </li>
                                        <li>
                                            <span>Color of roe:</span> {product?.color}
                                        </li>
                                        <li>
                                            <span>Texture:</span> {product?.textur}
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <span>Large</span>                                        
                                                <img src={dotFill} alt="dotFill"/>{" "}
                                                <img src={dotFill} alt="dotFill"/>{" "} 
                                                <img src={dotOutline} alt="dotFill"/>{" "}                                            
                                            <span className="right">Large</span>
                                        </li>
                                        <li>
                                            <span>Soft</span> 
                                                <img src={dotFill} alt="dotOutline" />{" "}
                                                <img src={dotFill} alt="dotOutline" />{" "} 
                                                <img src={dotFill} alt="dotOutline" />{" "}
                                                <img src={dotFill} alt="dotOutline" />{" "} 
                                                <img src={dotOutline} alt="dotOutline" />{" "}
                                            <span className="right">Firm</span>
                                        </li>
                                        <li>
                                            <span>Mild</span> 
                                                <img src={dotFill} alt="mild" />{" "}
                                                <img src={dotFill} alt="mild" />{" "} 
                                                <img src={dotFill} alt="mild" />{" "}
                                                <img src={dotFill} alt="mild" />{" "} 
                                                <img src={dotFill} alt="mild" />{" "}
                                            <span className="right">Intense</span>
                                        </li>
                                    </ul>
                                </Box>
                            )}
                            <Box className="sizeQuantityPrice">
                                {product?.categorie === 'caviar'  && (
                                    <Typography className="size" gutterBottom>
                                        Size - <strong>£ {product?.price}</strong>
                                    </Typography>
                                )}
                                <ToggleButtonGroup className="sizeButtongroup" color="primary" value={alignment} exclusive aria-label="Platform" >
                                    {variations.map((item) => {
                                        return(
                                                <ToggleButton value="{item.name}" 
                                                selected={item.name === selectedItem}
                                                    onChange={() => handleToggle(item.name, item.stock)}
                                                    sx={{
                                                        border: '2px solid',
                                                        borderColor: item.stock === 0 ? 'transparent' : (item.name === selectedItem ? 'black' : 'transparent'),
                                                        backgroundColor: item.stock === 0 ? '#f44336' : '#4caf50',
                                                        '&:hover': {
                                                        borderColor: item.stock === 0 ? 'transparent' : 'black',
                                                        },
                                                        '&.Mui-selected': {
                                                        borderColor: 'black',
                                                        },
                                                        transition: 'border-color 0.3s ease',
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    {item?.name}
                                                    {item?.stock === 0 ? (
                                                         <span className="not-available">Out of stock</span>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </ToggleButton>
                                        )
                                    })}
                                        {/*<ToggleButton value="30g">
                                            30g - A glimpse of pleasure
                                        </ToggleButton>
                                        <ToggleButton value="50g">
                                            50g - Indulgence for one
                                        </ToggleButton>
                                        <ToggleButton value="125g">
                                            125g - Perfect for two
                                        </ToggleButton>
                                        <ToggleButton value="250g">
                                            250g <span className="not-available">Out of stock</span>
                                        </ToggleButton>
                                        <ToggleButton value="500g">
                                            500g <span className="not-available">Out of stock</span>
                                        </ToggleButton>*/}
                                </ToggleButtonGroup>

                                <Typography className="quantity" gutterBottom>
                                    Quantity
                                </Typography>

                                <Box className="inputNumber">
                                    <button type="button" onClick={handleDownCount}>
                                        -
                                    </button>
                                    <input type="text" name="" id="" value={countVal} />
                                    <button type="button" onClick={handleUpCount}>
                                        +
                                    </button>
                                </Box>
                                <Divider />
                                {checkLogin ? (<Button sx={{width:"36% !important"}} type="submit" onClick={addGiftCard}>Add to Cart - £ {product?.price}</Button>) : (<Button type="submit" onClick={() =>redirectLogin()}>Login</Button>)}
                                
                            </Box>
                        </Grid>
                    </Grid>
                    {product?.categorie === 'caviar'  && (
                        <Box className="AccordionWrapper">
                            <Box className="Accordion">
                                <Box className="AccordionTitle" onClick={() => handleCaroselClick(0)}>
                                    <Typography variant="h6">Flavour Profile</Typography>
                                    <button>
                                        {bottomCarosel === 0 ? (
                                            <img src={iconMinus} alt="icon-minus" />
                                        ) : (
                                            <img src={iconPlus} alt="icon-plus" />
                                        )}
                                    </button>
                                </Box>
                                <Box className="AccordionDetails" sx={{ height: bottomCarosel === 0 ? "20px" : "0px" }} >
                                    <Typography variant="body1">
                                    {product.flavour_profile}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className="Accordion">
                                <Box className="AccordionTitle" onClick={() => handleCaroselClick(1)} >
                                    <Typography variant="h6">Combinations</Typography>
                                    <button>
                                        {bottomCarosel === 1 ? (
                                            <img src={iconMinus} alt="icon-minus"/>
                                        ) : (
                                            <img src={iconPlus}  alt="icon-plus"/>
                                        )}
                                    </button>
                                </Box>
                                <Box className="AccordionDetails" sx={{ height: bottomCarosel === 1 ? "20px" : "0px" }}>
                                    <Typography variant="body1">
                                    {product.combinations}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className="Accordion">
                                <Box className="AccordionTitle" onClick={() => handleCaroselClick(2)}>
                                    <Typography variant="h6">Allergens</Typography>
                                    <button>
                                        {bottomCarosel === 2 ? (
                                            <img src={iconMinus}  alt="icon-minus" />
                                        ) : (
                                            <img src={iconPlus}  alt="icon-plus" />
                                        )}
                                    </button>
                                </Box>
                                <Box className="AccordionDetails" sx={{ height: bottomCarosel === 2 ? "20px" : "0px" }}>
                                    <Typography variant="body1">{product.allergens}</Typography>
                                </Box>
                            </Box>

                            <Box className="Accordion">
                                <Box className="AccordionTitle" onClick={() => handleCaroselClick(3)}>
                                    <Typography variant="h6">
                                    Nutritional values per 100g 
                                    </Typography>
                                    <button>
                                        {bottomCarosel === 3 ? (
                                            <img src={iconMinus} alt="icon-minus" />
                                        ) : (
                                            <img src={iconPlus} alt="icon-plus" />
                                        )}
                                    </button>
                                </Box>
                                <Box className="AccordionDetails" sx={{ height: bottomCarosel === 3 ? "123px" : "0px" }}>
                                    <Typography variant="body1">
                                        <span>Energy: {nutritional?.energy}</span>
                                        <span>Fat: {nutritional?.fat}</span>
                                        <span>of which saturated fatty acids: {nutritional?.fatty_acids}</span>
                                        <span>Carbohydrates: {nutritional?.carbohydrates}</span>
                                        <span>of which sugar: {nutritional?.sugar}</span>
                                        <span>Protein: {nutritional?.protein}</span>
                                        <span>Salt: {nutritional?.salt}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            {/* mail section */}
            {/* <Box className="mailSection">
                <Typography className="ambHeader">
                    Join our <span className="TimesNowText">community</span>
                </Typography>
                <Typography className="summery14">
                    Sign up and stay up to date on product launches and pre order. By
                    entering your email address you agree to our Terms and Conditions
                    and Privacy and Cookie Policy.{" "}
                </Typography>

                <Box className="mailInputBox">
                    <input type="email" name="email" placeholder="Your email*" />
                    <Box className="submitBtn">{whiteArrow}</Box>
                </Box>

                <Box className="MailCheckBox">
                    <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                    <Typography>Please click here to accept our</Typography>
                    <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                    </Box>
                </Box>
            </Box> */}
            <EmailCommunity />
        <Footer />
        </>
    );
}
