
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { ProductService } from '../../Assets/Data';
import { Box, Typography } from "@mui/material";

export default function ResponsiveDemo({allStorie}) {
  const [products, setProducts] = useState([]);
  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case 'INSTOCK':
        return 'success';

      case 'LOWSTOCK':
        return 'warning';

      case 'OUTOFSTOCK':
        return 'danger';

      default:
        return null;
    }
  };

  useEffect(() => {
    ProductService?.getProductsSmall()?.then((data) => setProducts(data?.slice(0, 9)));
  }, []);

  const productTemplate = (product) => {
    return (
      <Box className="ovCard">
        <Box className="ovCInneerBox">
          <img src={product.img} />
          <Box className="ovTextBox">
            <Typography className="ovTbHeader">{product.title}</Typography>
            <Typography className="summery14">{product.summery}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <div className="card">
      <Carousel className="custom-carousel" circular value={products} numScroll={1} numVisible={3} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
    </div>
  )
}
