import React, { useState } from "react";
import "./style.css";

//components
import CookiePop from "../../Components/CookiePop";
import { Box, Typography } from "@mui/material";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";
import { whiteArrow } from "../../Assets/Data";

//images
import GarethImg1 from "../../Assets/Images/Gareth-WardImg1.jpg";
import GarethImg2 from "../../Assets/Images/Eleonora_Boscarelli_photography_1746-scaled.jpg";
import GarethImg3 from "../../Assets/Images/DSC_1775-2-scaled.jpg";
import GarethImg4 from "../../Assets/Images/DSC_1578-scaled-e1718127521660-300x289.jpg";
import GarethImg5 from "../../Assets/Images/Main-house-exterior-pc-Heather-Birnie-1024x683.jpg";

export default function GarethWard({ cookiePop, setCookiePop }) {
  const [check, setCheck] = useState(false);

  return (
    <>
      <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
      <NavBar />
      <Box className="garethPage">
        <Box className="garethFBox">
          <Box className="grTextBox">
            <Typography className="grHeaderText" data-aos="fade-up">
              Ambassador in Conversation: Gareth Ward
            </Typography>
            <Typography className="grNText" data-aos="fade-up">
              Introducing an N25 Caviar ambassador: Gareth Ward, executive chef
              and co-owner of Ynyshir, the most highly-awarded restaurant in
              Wales with two Michelin stars.
            </Typography>
            <Typography className="grNText" data-aos="fade-up">
              Known for bringing a bold, playful, and mind-blowing gastronomy
              experience; championing the best ingredients from across the
              globe; Gareth shares his outlook and passion for cooking and
              caviar.{" "}
            </Typography>

            <Box className="shopSLabelTextBox" data-aos="fade-up">
              <span data-aos="fade-up">Shop Gareth's Choice</span>
              <span className="ssltbLine"></span>
            </Box>
          </Box>
          <Box className="grImgBox">
            <img src={GarethImg1} />
          </Box>
        </Box>

        <Box className="garethSBox">
          <Box className="grsImgBox">
            <img src={GarethImg2} />
          </Box>
          <Box className="grsTextBox">
            <Box className="grSB1">
              <img src={GarethImg3} />
            </Box>
            <Box className="grSB2">
              <Typography className="grsb1TopText" data-aos="fade-up">
                "N25 Kaluga Caviar is my favourite, can not beat it in my
                opinion."
              </Typography>
              <img src={GarethImg4} />
              <Typography className="grsb1BoldText" data-aos="fade-up">
                How would you describe your food philosophy?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                My food philosophy is fun and flavour, that’s what it’s all
                about. We’re very ingredient-led here.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                What do you love about caviar?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                It’s just f**king amazing, delicious, right up my street.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                Which N25 Caviar is your favourite?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                N25 Kaluga caviar, can not beat it in my opinion.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                What’s your favourite way to enjoy caviar?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                Taking the tin lid off and sticking my tongue right in
                there!...Or on a beautiful bit of sourdough bread.
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                One thing that is always in your kitchen?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                Me!...No, Patrick McNutty my Head Chef! He’s always in the
                kitchen, the guy doesn’t stop
              </Typography>
              <Typography className="grsb1BoldText" data-aos="fade-up">
                What’s on the top of your bucket list?
              </Typography>
              <Typography className="grsb1NText" data-aos="fade-up">
                To retire and live out in Ibiza one day
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="garethTBox">
          <img src={GarethImg5} />

          <span className="discoverBtn">
            <Box className="shopSLabelTextBox">
              <span>Shop Gareth's Choice </span>
              <span className="ssltbLine"></span>
            </Box>
            <span className="dbsLine dbTops"></span>
            <span className="dbsLine dbRighs"></span>
            <span className="dbsLine dbBottoms"></span>
            <span className="dbsLine dbLefts"></span>
          </span>
        </Box>

        {/* mail section */}
        <Box className="mailSection">
          <Typography className="ambHeader">
            Join our <span className="TimesNowText">community</span>
          </Typography>
          <Typography className="summery14">
            Sign up and stay up to date on product launches and pre order. By
            entering your email address you agree to our Terms and Conditions
            and Privacy and Cookie Policy.{" "}
          </Typography>

          <Box className="mailInputBox">
            <input type="email" name="email" placeholder="Your email*" />
            <Box className="submitBtn">{whiteArrow}</Box>
          </Box>

          <Box className="MailCheckBox">
            <Box
              className={check ? "check checkActive" : "check"}
              onClick={() => setCheck(!check)}
            ></Box>
            <Typography>Please click here to accept our</Typography>
            <Box className="termsbox">
              <Typography>Terms and Conditions</Typography>
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </>
  );
}
